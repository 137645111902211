import styled from 'styled-components';
export const SearchInput = styled.div`
    input{
        -webkit-appearance: none;
        border: none;
        outline: none;
        padding: 10px;
        display: flex;
        margin: 10px auto;
        width: 90%;
    }
`