 import {notification} from 'antd';

 export const notify =(toaster)=>{
    const key = "updateable";
    let config = {
        key,
        message: (toaster.title || 'Notification'),
        description: (toaster.message || 'Action performed successfully'),
      };
      switch (toaster.type) {
        case 'success':
          notification.success(config);
          break;
        default:
          notification.open(config);
          break;
      }
  }