import styled from "styled-components";
import {Link} from "react-router-dom";
export const ItemContainer = styled(Link)`
  min-width: 90%;
  overflow: hidden;
  flex: 1 1 auto;
  display: flex;
  padding: 0 16px 8px;
  overflow: hidden;
  color: #c0b5c0;
  font-size: 14px;
  svg {
    font-size: 24px;
  }
  .icon {
    margin-right: 10px;
    transition: 0.5s all;
  }
  :hover .icon {
    transform: rotate3d(1, 1, 1, 360deg);
    color: #fff;
  }
`;
export const IconContainer = styled.span`
  padding: 0px 6px;
  font-size: 1.2em;
  &hover {
    color: #fff;
  }
`;
export const LabelContainer = styled.span`
  display: flex;
  font-size: 14px;
  align-self: center;
`;
