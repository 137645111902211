const UserActionTypes = {
  SET_CURRENT_USER: "SET_CURRENT_USER",
  GOOGLE_SIGN_IN_START: "GOOGLE_SIGN_IN_START",
  EMAIL_SIGN_IN_START: "EMAIL_SIGN_IN_START",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_FAILURE: "SIGN_IN_FAILURE",
  CHECK_USER_SESSION: "CHECK_USER_SESSION",
  SIGN_OUT_START: "SIGN_OUT_START",
  SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
  SIGN_OUT_FAILURE: "SIGN_OUT_FAILURE",
  SIGN_UP_START: "SIGN_UP_START",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  SIGN_UP_FAILURE: "SIGN_UP_FAILURE",
  SAVE_USER_PERMISSION_START: "SAVE_USER_PERMISSION_START",
  SELECT_USER_PERMISSION: "SELECT_USER_PERMISSION",

  LOAD_USER_PERMISSION: "LOAD_USER_PERMISSION",
  REMOVE_TOASTER_MESSAGE: "REMOVE_TOASTER_MESSAGE",
  SEND_COMPLAINT: "SEND_COMPLAINT",
  ONLINE_COMPLAINT_COMPLETE: "ONLINE_COMPLAINT_COMPLETE",
  CHANGE_PROFILE: "CHANGE_PROFILE",
  CHANGE_PROFILE_SUCCESS: "CHANGE_PROFILE_SUCCESS",
  CHANGE_PROFILE_FAILURE: "CHANGE_PROFILE_FAILURE",
  PROFILE_DROP: "PROFILE_DROP",
};

export default UserActionTypes;
