import React from "react";
import * as MaterialDesign from "react-icons/md";
import { Layout } from "antd";
import CountNotification from "../notifications/count-notifications.component";
import {
  selectCurrentUser,
  selectUserPermissions,
} from "../../redux/user/user.selectors";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import CustomScrollbars from "../../util/CustomScrollbars";
import { ItemContainer, IconContainer, LabelContainer } from "./sidebar.styles";
import Search from "../search/search.component";
import "./sidebar.styles.scss";
import { Animated } from "react-animated-css";
import { toggleCollapsedSideNav } from "../../redux/settings/settings.actions";
import { selectSiderStatus } from "../../redux/settings/settings.selectors";
const { Sider } = Layout;

class Sidebar extends React.Component {
  constructor() {
    super();
    this.state = {
      links: [
        {
          title: "Home",
          name: "Home",
          icon: "MdHome",
        },

        {
          title: "Complaints",
          name: "Complaints",
          icon: "MdPanTool",
        },
        {
          title: "Maps",
          name: "map",
          icon: "MdBubbleChart",
        },
      ],
      otherLinks: [
        {
          title: "Partners",
          name: "Partners",
          icon: "MdGroupWork",
        },
        {
          title: "Programs",
          name: "Programs",
          icon: "MdChromeReaderMode",
        },
        {
          title: "Projects",
          name: "Projects",
          icon: "MdDashboard",
        },
        {
          title: "Category",
          name: "Category",
          icon: "MdSwapVerticalCircle",
        },
        {
          title: "Type",
          name: "Type",
          icon: "MdSettings",
        },
        {
          title: "Status",
          name: "Status",
          icon: "MdInfo",
        },
        {
          title: "Province",
          name: "Province",
          icon: "MdLanguage",
        },
        {
          title: "District",
          name: "District",
          icon: "MdLocationOn",
        },
        {
          title: "Tehsil",
          name: "Tehsil",
          icon: "MdLanguage",
        },

        {
          title: "Union Council",
          name: "Uc",
          icon: "MdLanguage",
        },
        {
          title: "Village",
          name: "Village",
          icon: "MdLanguage",
        },
        {
          title: "Source",
          name: "Source",
          icon: "MdInfo",
        },
        {
          title: "Tag",
          name: "Tag",
          icon: "MdTurnedIn",
        },
        {
          title: "Users",
          name: "Users",
          icon: "MdAccountBox",
        },
        {
          title: "Document Types",
          name: "document",
          icon: "MdBook",
        },
        {
          title: "Permissions",
          name: "Permissions",
          icon: "MdExtension",
        },
      ],
      searchField: "",
      searching: false,
    };
  }
  handleChange = (e) => {
    this.setState({ searchField: e.target.value });
  };
  toggleSearching = () => {
    this.setState({ searching: !this.state.searching });
  };

  render() {
    const { currentUser, handleCollapse, isSidebarCollapsed } = this.props;

    const { links, searchField, searching, otherLinks } = this.state;

    let allowedLinks = [];
    let allowedOtherLinks = [];
    links.map((elem) => {
      if (
        currentUser.permissions &&
        currentUser.permissions.find(
          (x) => x.permissionName.toLowerCase() === elem.name.toLowerCase()
        )
      )
        allowedLinks.push(elem);
    });
    otherLinks.map((elem) => {
      if (
        currentUser.permissions &&
        currentUser.permissions.find(
          (x) => x.permissionName.toLowerCase() === elem.name.toLowerCase()
        )
      )
        allowedOtherLinks.push(elem);
    });
    const filteredLinks = allowedLinks.filter((link) =>
      link.name.toLowerCase().includes(searchField.toLowerCase())
    );
    const otherFilteredLinks = allowedOtherLinks.filter((link) =>
      link.name.toLowerCase().includes(searchField.toLowerCase())
    );

    return (
      <Animated
        animationIn="bounceInLeft"
        animationOut="fadeOut"
        isVisible={true}
      >
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          className="gx-app-sidebar side-bar gx-sidebar-content"
          theme="light"
          collapsible
          collapsed={isSidebarCollapsed}
          onCollapse={handleCollapse}
        >
          <div className="gx-sidebar-notifications">
            <CountNotification toggleSearching={this.toggleSearching} />
          </div>
          {searching ? (
            <Search
              className="sidebar-search"
              placeholder="Search"
              onChange={this.handleChange}
              type="search"
            />
          ) : null}
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            {filteredLinks.map((item, index) => {
              const MdIcon = MaterialDesign[item.icon];
              return (
                <Animated
                  key={item.name}
                  animationIn="bounceInLeft"
                  animationOut="fadeOut"
                  isVisible={true}
                  animationInDuration={500 * (index + 1)}
                >
                  <ItemContainer
                    to={`/${item.name.toLowerCase()}`}
                    key={item.name}
                  >
                    <IconContainer className="icon">
                      <MdIcon />
                    </IconContainer>
                    <LabelContainer className="sidebar-label">
                      {item.title}
                    </LabelContainer>
                  </ItemContainer>
                </Animated>
              );
            })}
            {isSidebarCollapsed ? null : (
              <span className="side-bar-span">Lookups and Filters</span>
            )}
            {otherFilteredLinks.map((item, index) => {
              const MdIcon = MaterialDesign[item.icon];
              return (
                <Animated
                  key={item.name}
                  animationIn="bounceInLeft"
                  animationOut="fadeOut"
                  isVisible={true}
                  animationInDuration={500 * (index + 1)}
                >
                  <ItemContainer
                    to={`/${item.name.toLowerCase()}`}
                    key={item.name}
                  >
                    <IconContainer className="icon">
                      <MdIcon />
                    </IconContainer>
                    <LabelContainer className="sidebar-label">
                      {item.title}
                    </LabelContainer>
                  </ItemContainer>
                </Animated>
              );
            })}
          </CustomScrollbars>
        </Sider>
      </Animated>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentUserPermissions: selectUserPermissions,
  isSidebarCollapsed: selectSiderStatus,
});
const mapDispatchToProps = (dispatch) => ({
  handleCollapse: (collapse) => dispatch(toggleCollapsedSideNav(collapse)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
