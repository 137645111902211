import UserActionTypes from "./user.types";
import { handlePermissionChange } from "./user.actions";

const INITIAL_STATE = {
  currentUser: null,
  userPermissions: [],
  selectedPermissions: [],
  error: null,
  isLoading: false,
  toasterMessage: null,
  profile: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.PROFILE_DROP:
      return {
        ...state,
        profile: !state.profile,
      };
    case UserActionTypes.CHANGE_PROFILE_SUCCESS:
      return {
        ...state,
        currentUser: { ...state.currentUser, profile: action.profile },
        profile: false,
      };
    case UserActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        error: null,
        isLoading: false,
      };
    case UserActionTypes.SEND_COMPLAINT:
      return {
        ...state,
        isLoading: true,
        toasterMessage: "",
      };
    case UserActionTypes.REMOVE_TOASTER_MESSAGE:
      return {
        ...state,
        toasterMessage: "",
      };
    case UserActionTypes.ONLINE_COMPLAINT_COMPLETE:
      return {
        ...state,
        isLoading: false,
        toasterMessage: "",
      };
    case UserActionTypes.EMAIL_SIGN_IN_START:
      return {
        ...state,
        isLoading: true,
      };
    case UserActionTypes.SELECT_USER_PERMISSION:
      const { sourceSelectedKeys, targetSelectedKeys } = action.payload;
      return {
        ...state,
        selectedPermissions: [...sourceSelectedKeys, ...targetSelectedKeys],
      };
    case UserActionTypes.LOAD_USER_PERMISSION:
      return {
        ...state,
        userPermissions: action.payload.permissions,
      };
    case UserActionTypes.SIGN_OUT_SUCCESS:
      return {
        ...state,
        currentUser: null,
        error: null,
      };
    case UserActionTypes.SAVE_USER_PERMISSION_START:
      return {
        ...state,
        userPermissions: action.payload.next,
      };
    case UserActionTypes.CHECK_USER_SESSION:
      return {
        ...state,
        toasterMessage: null,
        isLoading: false,
      };
    case UserActionTypes.SIGN_IN_FAILURE:
    case UserActionTypes.SIGN_OUT_FAILURE:
    case UserActionTypes.SIGN_UP_FAILURE:
      return {
        ...state,
        error: action.payload,
        toasterMessage: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default userReducer;
