import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  selectCurrentUser,
  selectProfileDrop,
} from "../../redux/user/user.selectors";
import { selectSiderStatus } from "../../redux/settings/settings.selectors";
import { signOutStart, profileDrop } from "../../redux/user/user.actions";

import { ReactComponent as Logo } from "../../assets/logo-orignal.svg";
import { ReactComponent as LogoCollapsed } from "../../assets/logo-collapsed.svg";
import PhotoUploader from "../uploader/uploader.photo.component";
import { selectUploadedFiles } from "../../redux/upload/upload.selectors";

import {
  HeaderContainer,
  LogoContainer,
  OptionsContainer,
  OptionLink,
  LogoutIcon,
  OrganizationContainer,
  LogoWrapper,
  UserMeta,
  BeforeSignInContainer,
  ProfileDropDown,
} from "./header.styles";
import { Icon } from "antd";
import { Animated } from "react-animated-css";
import { Link } from "react-router-dom";
import Profile from "./profile.component";
import Tour from "reactour";
import { useHistory } from "react-router-dom";
const Header = ({
  currentUser,
  hidden,
  signOutStart,
  isCollapsed,
  profile,
  profileDrop,
  match,
}) => {
  let history = useHistory();
  const [state, setState] = useState({ tour: false });
  const closeTour = () => {
    setState({ ...state, tour: false });
  };
  const openTour = () => {
    setState({ ...state, tour: true });
  };
  const stepSetup = (step) => {
    //alert(step);
    setState({ ...state, tour: false });
    //alert(step);
  };
  const steps = [
    {
      selector: ".help",
      content:
        "I am here to help. Just click whenever you need any help. Let's start with the current page. I will always explain the active page when you click here.",
    },
    // ...
  ];
  if (history.location.pathname === "/") {
    steps.push(
      {
        selector: ".manual-download",
        content:
          "Here is a download link for the user manual. If you are comfortable with pdf explaination, use this to download the manual.",
      },
      {
        selector: ".profile-change",
        content: "You can change your profile from here.",
      },
      {
        selector: ".sidebar",
        content:
          "This is your navigation panel. You can go to any page from this panel. You can click help on each page to get help related to the active page.",
      },
      {
        selector: ".gx-sidebar-notifications",
        content:
          "If your panel has too many links you can always use the search icon to do a quick search through the links. You can use the notifiction icon to go to the notifictions sent.",
      }
    );
  }
  if (history.location.pathname === "/complaints") {
    steps.push(
      {
        selector: ".tabular-content-holder",
        content:
          "This is the page to list all the complaints that are entered in the system.",
      },
      {
        selector: ".action-bar",
        content:
          "These are the actions you can perform. You can use the 'New Complaint' button to add new complaints in the system.",
      },
      {
        selector: ".dashboard-filter",
        content:
          "Use these filters to filter out complaints based on your selecton. You can use the date filter to get complaints received between specific dates. You can reset the filter form by using the reset button. Once you fill out the filter form, use the filter button and wait for the data to refresh.",
      }
    );
  }

  const toggleProfileDrop = () => {
    profileDrop();
  };
  return (
    <HeaderContainer isCollapsed={isCollapsed}>
      <LogoContainer to="/">
        <LogoWrapper>
          <Logo className="logo" />
        </LogoWrapper>
      </LogoContainer>
      {currentUser ? (
        <div className="header-right-sec-wrapper">
          <OrganizationContainer>
            {currentUser ? currentUser.program : null}
          </OrganizationContainer>
          <OptionsContainer>
            {currentUser ? (
              <>
                <UserMeta className="gx-login-list">
                  <li>
                    <span onClick={() => toggleProfileDrop()}>
                      {currentUser.profile && currentUser.profile.file ? (
                        <Profile />
                      ) : (
                        <Icon type="user" />
                      )}{" "}
                      {currentUser.displayName}
                    </span>
                    {profile ? (
                      <ProfileDropDown className="profile-dropdown">
                        <ul>
                          <li>
                            <label className="link profile-change">
                              <Icon type="setting" />
                              Change Profile
                              <PhotoUploader
                                uploadType="ProfilePicture"
                                className="uploader"
                              />
                            </label>
                          </li>
                          {currentUser.isAdmin ? (
                            <li className="manual-download">
                              <a href="/NCA-admin-manual-version1.1.pdf">
                                <Icon type="book" />
                                Users Manual
                              </a>
                            </li>
                          ) : (
                            <li className="manual-download">
                              <a href="/NCA-partner-manual-version1.1.pdf">
                                <Icon type="book" />
                                Users Manual
                              </a>
                            </li>
                          )}
                          <li className="help">
                            <label onClick={openTour}>
                              <Icon type="link" />
                              Need Help?
                            </label>
                            <Tour
                              steps={steps}
                              isOpen={state.tour}
                              startAt={0}
                              onRequestClose={closeTour}
                            />
                          </li>
                        </ul>
                      </ProfileDropDown>
                    ) : null}
                  </li>
                  <li>
                    <Icon type="appstore" /> {currentUser.partner}
                  </li>
                </UserMeta>
                <OptionLink as="div" to="#" onClick={signOutStart}>
                  SIGN OUT
                </OptionLink>
              </>
            ) : null}
            {currentUser ? (
              <LogoutIcon>
                <Icon type="logout" />
              </LogoutIcon>
            ) : null}
          </OptionsContainer>
        </div>
      ) : (
        <BeforeSignInContainer>
          <Icon type="appstore" />
          <Link className="online-form" to="/online-complaint">
            Online Complaint
          </Link>
        </BeforeSignInContainer>
      )}
    </HeaderContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  isCollapsed: selectSiderStatus,
  profile: selectProfileDrop,
});

const mapDispatchToProps = (dispatch) => ({
  signOutStart: () => dispatch(signOutStart()),
  profileDrop: () => dispatch(profileDrop()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
