require("dotenv").config();
//let baseurl = "http://api.ncacomplaints.pk";
let baseurl = "https://ncacomplaints.pk/api";
if (
  !process.env.NODE_ENV ||
  process.env.NODE_ENV === "development" ||
  process.env.NODE_ENV === "demo"
) {
  // dev code
  baseurl = "http://localhost:4000";
}
export const links = {
  BASE: baseurl,
  UPLOAD: baseurl + "/upload",
  ONLINE: baseurl + "/complaint/online",

  SIGN_UP: baseurl + "/user",
  SIGN_IN: baseurl + "/user/login",
  CHANGE_PROFILE: baseurl + "/user/change-profile",

  FETCH_COMPLAINT_DROPDOWNS: baseurl + "/complaint/filter-options",

  FETCH_HOME_START: baseurl + "/home",
  FETCH_COMPLAINT_START: baseurl + "/complaint",
  FETCH_COMPLAINT_DETAIL_START: baseurl + "/complaint/detail",
  FETCH_DETAIL_START: baseurl + "/partner/detail",
  PARTNER: baseurl + "/partner/detail",
  PROGRAM: baseurl + "/program/detail",
  PROJECT: baseurl + "/project/detail",
  DOCUMENT: baseurl + "/document/detail",
  CATEGORY: baseurl + "/category/detail",
  TYPE: baseurl + "/type/detail",
  PERMISSION: baseurl + "/permission/detail",
  TEHSIL: baseurl + "/tehsil/detail",
  UC: baseurl + "/uc/detail",
  VILLAGE: baseurl + "/village/detail",
  PROVINCE: baseurl + "/province/detail",
  SOURCE: baseurl + "/source/detail",
  STATUS: baseurl + "/status/detail",
  TAG: baseurl + "/tag/detail",
  DISTRICT: baseurl + "/district/detail",
  COMPLAINT: baseurl + "/complaint",
  ADD_COMPLAINT_STATUS_START: baseurl + "/complaint/status",
  HOME: baseurl + "/home",
  FETCH_COMMENT_START: baseurl + "/comment",
  FETCH_NOTIFICATION_START: baseurl + "/notifications",
  FETCH_DOCUMENT_START: baseurl + "/document",
  FETCH_CATEGORY_START: baseurl + "/category",
  FETCH_TYPE_START: baseurl + "/type",
  FETCH_PROVINCE_START: baseurl + "/province",
  FETCH_DISTRICT_START: baseurl + "/district",
  FETCH_PARTNER_START: baseurl + "/partner",
  FETCH_PROGRAM_START: baseurl + "/program",
  FETCH_PROJECT_START: baseurl + "/project",
  FETCH_STATUS_START: baseurl + "/status",
  FETCH_SOURCE_START: baseurl + "/source",
  FETCH_TAG_START: baseurl + "/tag",
  FETCH_USER_START: baseurl + "/user",
  FETCH_PERMISSION_START: baseurl + "/permission",
  FETCH_UC_START: baseurl + "/uc",
  FETCH_TEHSIL_START: baseurl + "/tehsil",
  FETCH_VILLAGE_START: baseurl + "/village",

  CREATE_COMPLAINT: baseurl + "/complaint/create",
  CREATE_DOCUMENT: baseurl + "/document/create",
  CREATE_CATEGORY: baseurl + "/category/create",
  CREATE_TYPE: baseurl + "/type/create",
  CREATE_PROVINCE: baseurl + "/province/create",
  CREATE_DISTRICT: baseurl + "/district/create",
  CREATE_UC: baseurl + "/uc/create",
  CREATE_TEHSIL: baseurl + "/tehsil/create",
  CREATE_VILLAGE: baseurl + "/village/create",
  CREATE_PARTNER: baseurl + "/partner/create",
  CREATE_PROGRAM: baseurl + "/program/create",
  CREATE_PROJECT: baseurl + "/project/create",
  CREATE_STATUS: baseurl + "/status/create",
  CREATE_SOURCE: baseurl + "/source/create",
  CREATE_TAG: baseurl + "/tag/create",
  CREATE_USER: baseurl + "/user/create",
  CREATE_PERMISSION: baseurl + "/permission/create",
  CREATE_COMMENT: baseurl + "/comment/create",
  SAVE_USER_PERMISSION_START: baseurl + "/user/permission",
};
