import React, { useEffect, lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Header from "./components/header/header.component";
import Spinner from "./components/spinner/spinner.component";
import ErrorBoundary from "./components/error-boundary/error-boundary.component";

import { GlobalStyle } from "./global.styles";

import { selectCurrentUser } from "./redux/user/user.selectors";

import { checkUserSession } from "./redux/user/user.actions";
import Sidebar from "./components/sidebar/sidebar.component";
//import 'antd/dist/antd.css';
import "./dashboard.styles.scss";
import "./assets/vendors/noir-pro/styles.css";
import { Animated } from "react-animated-css";

const Charts = lazy(() => import("./pages/chart/chart.component"));
const HomePage = lazy(() => import("./pages/homepage/homepage.component"));
const ProjectPage = lazy(() => import("./pages/project/project.component"));
const PartnerPage = lazy(() => import("./pages/partner/partner.component"));
const ProgramPage = lazy(() => import("./pages/program/program.component"));
const DocumentPage = lazy(() => import("./pages/document/document.component"));
const CategoryPage = lazy(() => import("./pages/category/category.component"));
const TypePage = lazy(() => import("./pages/type/type.component"));
const ProvincePage = lazy(() => import("./pages/province/province.component"));
const UcPage = lazy(() => import("./pages/uc/uc.component"));
const TehsilPage = lazy(() => import("./pages/tehsil/tehsil.component"));
const VillagePage = lazy(() => import("./pages/village/village.component"));
const DistrictPage = lazy(() => import("./pages/district/district.component"));
const StatusPage = lazy(() => import("./pages/status/status.component"));
const SourcePage = lazy(() => import("./pages/source/source.component"));
const NotificationPage = lazy(() =>
  import("./pages/notificaiton/notification.component")
);
const ComplaintPage = lazy(() =>
  import("./pages/complaint/complaint.component")
);
const ReportPage = lazy(() => import("./pages/report/reportpage.component"));
const TagPage = lazy(() => import("./pages/tag/tag.component"));
const MapPage = lazy(() => import("./pages/map/map.component"));
const UserPage = lazy(() => import("./pages/user/user.component"));
const OnlineComplaintPage = lazy(() =>
  import("./pages/online-complaint/online-complaint.page")
);
const PermissionPage = lazy(() =>
  import("./pages/permission/permission.component")
);

const SignInAndSignUpPage = lazy(() =>
  import("./pages/sign-in-and-sign-up/sign-in-and-sign-up.component")
);
//const Dashboard = lazy(()=>import('./containers/App/index'));
//const HomePage = lazy(() => import('./pages/homepage/homepage.component'));

const App = ({ checkUserSession, currentUser }) => {
  useEffect(() => {
    checkUserSession();
  }, [checkUserSession]);
  //currentUser = true;
  return (
    <div>
      <GlobalStyle />
      <Header />
      <div className={currentUser ? `page` : `full-page`}>
        {currentUser ? (
          <div className="sidebar">
            <Sidebar />
          </div>
        ) : null}

        <Switch>
          <ErrorBoundary>
            <Suspense fallback={<Spinner />}>
              <Animated
                animationIn="bounceIn"
                animationOut="fadeOut"
                isVisible={true}
              >
                <Route
                  exact
                  path="/"
                  component={currentUser ? Charts : SignInAndSignUpPage}
                />
                <Route path="/charts" component={Charts} />
                <Route
                  exact
                  path="/home"
                  component={currentUser ? Charts : SignInAndSignUpPage}
                />
                <Route
                  exact
                  path="/r"
                  component={currentUser ? HomePage : SignInAndSignUpPage}
                />
                <Route
                  path="/projects"
                  component={currentUser ? ProjectPage : SignInAndSignUpPage}
                />
                <Route
                  path="/partners"
                  component={currentUser ? PartnerPage : SignInAndSignUpPage}
                />
                <Route
                  path="/programs"
                  component={currentUser ? ProgramPage : SignInAndSignUpPage}
                />
                <Route
                  path="/document"
                  component={currentUser ? DocumentPage : SignInAndSignUpPage}
                />
                <Route
                  path="/category"
                  component={currentUser ? CategoryPage : SignInAndSignUpPage}
                />
                <Route
                  path="/type"
                  component={currentUser ? TypePage : SignInAndSignUpPage}
                />
                <Route
                  path="/Province"
                  component={currentUser ? ProvincePage : SignInAndSignUpPage}
                />
                <Route
                  path="/uc"
                  component={currentUser ? UcPage : SignInAndSignUpPage}
                />
                <Route
                  path="/tehsil"
                  component={currentUser ? TehsilPage : SignInAndSignUpPage}
                />
                <Route
                  path="/village"
                  component={currentUser ? VillagePage : SignInAndSignUpPage}
                />
                <Route
                  path="/district"
                  component={currentUser ? DistrictPage : SignInAndSignUpPage}
                />
                <Route
                  path="/status"
                  component={currentUser ? StatusPage : SignInAndSignUpPage}
                />
                <Route
                  path="/source"
                  component={currentUser ? SourcePage : SignInAndSignUpPage}
                />
                <Route
                  path="/notifications"
                  component={
                    currentUser ? NotificationPage : SignInAndSignUpPage
                  }
                />
                <Route
                  path="/complaints"
                  component={currentUser ? ComplaintPage : SignInAndSignUpPage}
                />
                <Route
                  path="/tag"
                  component={currentUser ? TagPage : SignInAndSignUpPage}
                />
                <Route
                  path="/reports"
                  component={currentUser ? ReportPage : SignInAndSignUpPage}
                />
                <Route
                  path="/users"
                  component={currentUser ? UserPage : SignInAndSignUpPage}
                />
                <Route
                  path="/map"
                  component={currentUser ? MapPage : SignInAndSignUpPage}
                />
                <Route
                  path="/permissions"
                  component={currentUser ? PermissionPage : SignInAndSignUpPage}
                />
                <Route
                  path="/online-complaint"
                  component={OnlineComplaintPage}
                />
                <Route
                  exact
                  path="/signin"
                  render={() =>
                    currentUser ? <Redirect to="/" /> : <SignInAndSignUpPage />
                  }
                />
              </Animated>
            </Suspense>
          </ErrorBoundary>
        </Switch>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  checkUserSession: () => dispatch(checkUserSession()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
