import React from "react";
import Lookup from "../../components/lookup/lookup.component";
export const data = {
  FETCH_HOME_START: {
    headers: [
      {
        Header: "Info",
        columns: [
          {
            Header: "ID",
            accessor: "sr",
          },
          {
            Header: "Complainant Name",
            accessor: "complainantName",
          },
          {
            Header: "Complainant Gender",
            accessor: "complainantGender",
          },
          {
            Header: "Details",
            accessor: "complaintDetails",
          },
        ],
      },
    ],
    data: [],
  },
  FETCH_NOTIFICATION_START: {
    headers: [
      {
        Header: "Info",
        columns: [
          {
            Header: "ID",
            accessor: "sr",
          },
          {
            Header: "Notification To",
            accessor: "notificationTo",
          },
          {
            Header: "Type",
            accessor: "notificationType",
          },
          {
            Header: "Details",
            accessor: "notificationDetails",
          },
        ],
      },
    ],
    data: [],
  },
  FETCH_COMPLAINT_START: {
    headers: [
      {
        Header: "Info",
        columns: [
          {
            Header: "ID",
            accessor: "sr",
          },
          {
            Header: "Complainant Name",
            accessor: "complainantName",
          },
          {
            Header: "Complainant Gender",
            accessor: "complainantGender",
          },
          {
            Header: "Status",
            accessor: "complaintStatus",
            Cell: (props) => (
              <Lookup
                data={props}
                type={"nameFromKey"}
                accessor={"statusName"}
              />
            ),
          },
          {
            Header: "Date",
            accessor: "complaintReceived",
            Cell: (props) => (
              <Lookup data={props} type={"formatDate"} accessor={"date"} />
            ),
          },
        ],
      },
    ],
    data: [
      {
        id: 1,
        complainantName: "Arslan",
        complaintSource: "This is project detail",
        complaintDetails: "My Project",
      },
      {
        id: 1,
        complainantName: "Fareed",
        complaintSource: "This is project detail",
        complaintDetails: "My Project",
      },
    ],
  },
  FETCH_COMMENT_START: {
    headers: [
      {
        Header: "Info",
        columns: [
          {
            Header: "ID",
            accessor: "sr",
          },
          {
            Header: "Complainant Name",
            accessor: "commentTitle",
          },
        ],
      },
    ],
    data: [
      {
        id: 1,
        commentTitle: "Arslan",
      },
    ],
  },
  FETCH_REPORT_START: {
    data: [],
    headers: [],
  },
  FETCH_PARTNER_START: {
    headers: [
      {
        Header: "Info",
        columns: [
          {
            Header: "ID",
            accessor: "sr",
          },
          {
            Header: "Name",
            accessor: "partnerName",
          },
          {
            Header: "Partner Details",
            accessor: "partnerDetails",
          },
        ],
      },
      {
        Header: "Count",
        columns: [
          {
            Header: "Complaints",
            accessor: "complaints",
          },
        ],
      },
    ],
    data: [
      {
        name: "First Partner",
        detail: "This is partner detail",
        complaints: 20,
      },
      {
        name: "Mushahid",
        detail: "This is another partner detail",
        complaints: 300,
      },
      {
        name: "First Partner",
        detail: "This is partner detail",
        complaints: 14,
      },
      {
        name: "Mushahid",
        detail: "This is another partner detail",
        complaints: 20,
      },
      {
        name: "First Partner",
        detail: "This is partner detail",
        complaints: 500,
      },
      {
        name: "Mushahid",
        detail: "This is another partner detail",
        complaints: 100,
      },
      {
        name: "First Partner",
        detail: "This is partner detail",
        complaints: 600,
      },
      {
        name: "Mushahid",
        detail: "This is another partner detail",
        complaints: 200,
      },
      {
        name: "First Partner",
        detail: "This is partner detail",
        complaints: 802,
      },
      {
        name: "Mushahid",
        detail: "This is another partner detail",
        complaints: 200,
      },
    ],
  },
  FETCH_PROGRAM_START: {
    headers: [
      {
        Header: "Info",
        columns: [
          {
            Header: "ID",
            accessor: "sr",
          },
          {
            Header: "Name",
            accessor: "programName",
          },
          {
            Header: "Program Details",
            accessor: "programDetails",
          },
        ],
      },
      {
        Header: "Count",
        columns: [
          {
            Header: "Complaints",
            accessor: "complaints",
          },
        ],
      },
    ],
    data: [
      {
        name: "My Program",
        detail: "This is Program detail",
        complaints: 200,
      },
      {
        name: "My Another Program",
        detail: "This is another Program detail",
        complaints: 200,
      },
      {
        name: "My Program",
        detail: "This is Program detail",
        complaints: 200,
      },
      {
        name: "My Another Program",
        detail: "This is another Program detail",
        complaints: 200,
      },
      {
        name: "My Program",
        detail: "This is Program detail",
        complaints: 200,
      },
      {
        name: "My Another Program",
        detail: "This is another Program detail",
        complaints: 200,
      },
      {
        name: "My Program",
        detail: "This is Program detail",
        complaints: 200,
      },
      {
        name: "My Another Program",
        detail: "This is another Program detail",
        complaints: 200,
      },
      {
        name: "My Program",
        detail: "This is Program detail",
        complaints: 200,
      },
      {
        name: "My Another Program",
        detail: "This is another Program detail",
        complaints: 200,
      },
    ],
  },
  FETCH_PROJECT_START: {
    headers: [
      {
        Header: "Info",
        columns: [
          {
            Header: "ID",
            accessor: "sr",
          },
          {
            Header: "Name",
            accessor: "projectName",
          },
          {
            Header: "Project Details",
            accessor: "projectDetails",
          },
        ],
      },
      {
        Header: "Count",
        columns: [
          {
            Header: "Complaints",
            accessor: "complaints",
          },
        ],
      },
    ],
    data: [
      {
        name: "My Project",
        detail: "This is project detail",
        complaints: 200,
      },
      {
        name: "Another Project",
        detail: "This is another project detail",
        complaints: 200,
      },
      {
        name: "My Project",
        detail: "This is project detail",
        complaints: 200,
      },
      {
        name: "Another Project",
        detail: "This is another project detail",
        complaints: 200,
      },
      {
        name: "My Project",
        detail: "This is project detail",
        complaints: 200,
      },
      {
        name: "Another Project",
        detail: "This is another project detail",
        complaints: 200,
      },
      {
        name: "My Project",
        detail: "This is project detail",
        complaints: 200,
      },
      {
        name: "Another Project",
        detail: "This is another project detail",
        complaints: 200,
      },
      {
        name: "My Project",
        detail: "This is project detail",
        complaints: 200,
      },
      {
        name: "Another Project",
        detail: "This is another project detail",
        complaints: 200,
      },
      {
        name: "My Project",
        detail: "This is project detail",
        complaints: 200,
      },
      {
        name: "Another Project",
        detail: "This is another project detail",
        complaints: 200,
      },
      {
        name: "My Project",
        detail: "This is project detail",
        complaints: 200,
      },
      {
        name: "Another Project",
        detail: "This is another project detail",
        complaints: 200,
      },
    ],
  },
  FETCH_CATEGORY_START: {
    headers: [
      {
        Header: "Info",
        columns: [
          {
            Header: "ID",
            accessor: "sr",
          },
          {
            Header: "Name",
            accessor: "categoryName",
          },
          {
            Header: "Category Details",
            accessor: "categoryDetails",
          },
        ],
      },
      {
        Header: "Count",
        columns: [
          {
            Header: "Complaints",
            accessor: "complaints",
          },
        ],
      },
    ],
    data: [
      {
        name: "First Category",
        detail: "This is Category detail",
        complaints: 20,
      },
      {
        name: "Mushahid",
        detail: "This is another Category detail",
        complaints: 300,
      },
      {
        name: "First Category",
        detail: "This is Category detail",
        complaints: 14,
      },
      {
        name: "Mushahid",
        detail: "This is another Category detail",
        complaints: 20,
      },
      {
        name: "First Category",
        detail: "This is Category detail",
        complaints: 500,
      },
      {
        name: "Mushahid",
        detail: "This is another Category detail",
        complaints: 100,
      },
      {
        name: "First Category",
        detail: "This is Category detail",
        complaints: 600,
      },
      {
        name: "Mushahid",
        detail: "This is another Category detail",
        complaints: 200,
      },
      {
        name: "First Category",
        detail: "This is Category detail",
        complaints: 802,
      },
      {
        name: "Mushahid",
        detail: "This is another Category detail",
        complaints: 200,
      },
    ],
  },
  FETCH_DOCUMENT_START: {
    headers: [
      {
        Header: "Info",
        columns: [
          {
            Header: "ID",
            accessor: "sr",
          },
          {
            Header: "Name",
            accessor: "documentName",
          },
          {
            Header: "Document Details",
            accessor: "documentDetails",
          },
        ],
      },
      {
        Header: "Count",
        columns: [
          {
            Header: "Complaints",
            accessor: "complaints",
          },
        ],
      },
    ],
    data: [
      {
        name: "First Document",
        detail: "This is Document detail",
        complaints: 20,
      },
      {
        name: "Mushahid",
        detail: "This is another Document detail",
        complaints: 300,
      },
      {
        name: "First Document",
        detail: "This is Document detail",
        complaints: 14,
      },
      {
        name: "Mushahid",
        detail: "This is another Document detail",
        complaints: 20,
      },
      {
        name: "First Document",
        detail: "This is Document detail",
        complaints: 500,
      },
      {
        name: "Mushahid",
        detail: "This is another Document detail",
        complaints: 100,
      },
      {
        name: "First Document",
        detail: "This is Document detail",
        complaints: 600,
      },
      {
        name: "Mushahid",
        detail: "This is another Document detail",
        complaints: 200,
      },
      {
        name: "First Document",
        detail: "This is Document detail",
        complaints: 802,
      },
      {
        name: "Mushahid",
        detail: "This is another Document detail",
        complaints: 200,
      },
    ],
  },
  FETCH_TYPE_START: {
    headers: [
      {
        Header: "Info",
        columns: [
          {
            Header: "ID",
            accessor: "sr",
          },
          {
            Header: "Name",
            accessor: "typeName",
          },
          {
            Header: "TYPE Details",
            accessor: "typeDetails",
          },
        ],
      },
      {
        Header: "Count",
        columns: [
          {
            Header: "Complaints",
            accessor: "complaints",
          },
        ],
      },
    ],
    data: [
      {
        name: "First TYPE",
        detail: "This is TYPE detail",
        complaints: 20,
      },
      {
        name: "Mushahid",
        detail: "This is another TYPE detail",
        complaints: 300,
      },
      {
        name: "First TYPE",
        detail: "This is TYPE detail",
        complaints: 14,
      },
      {
        name: "Mushahid",
        detail: "This is another TYPE detail",
        complaints: 20,
      },
      {
        name: "First TYPE",
        detail: "This is TYPE detail",
        complaints: 500,
      },
      {
        name: "Mushahid",
        detail: "This is another TYPE detail",
        complaints: 100,
      },
      {
        name: "First TYPE",
        detail: "This is TYPE detail",
        complaints: 600,
      },
      {
        name: "Mushahid",
        detail: "This is another TYPE detail",
        complaints: 200,
      },
      {
        name: "First TYPE",
        detail: "This is TYPE detail",
        complaints: 802,
      },
      {
        name: "Mushahid",
        detail: "This is another TYPE detail",
        complaints: 200,
      },
    ],
  },
  FETCH_STATUS_START: {
    headers: [
      {
        Header: "Info",
        columns: [
          {
            Header: "ID",
            accessor: "sr",
          },
          {
            Header: "Name",
            accessor: "statusName",
          },
          {
            Header: "Status Details",
            accessor: "statusDetails",
          },
        ],
      },
      {
        Header: "Count",
        columns: [
          {
            Header: "Complaints",
            accessor: "complaints",
          },
        ],
      },
    ],
    data: [
      {
        name: "First Status",
        detail: "This is Status detail",
        complaints: 20,
      },
      {
        name: "Mushahid",
        detail: "This is another Status detail",
        complaints: 300,
      },
      {
        name: "First Status",
        detail: "This is Status detail",
        complaints: 14,
      },
      {
        name: "Mushahid",
        detail: "This is another Status detail",
        complaints: 20,
      },
      {
        name: "First Status",
        detail: "This is Status detail",
        complaints: 500,
      },
      {
        name: "Mushahid",
        detail: "This is another Status detail",
        complaints: 100,
      },
      {
        name: "First Status",
        detail: "This is Status detail",
        complaints: 600,
      },
      {
        name: "Mushahid",
        detail: "This is another Status detail",
        complaints: 200,
      },
      {
        name: "First Status",
        detail: "This is Status detail",
        complaints: 802,
      },
      {
        name: "Mushahid",
        detail: "This is another Status detail",
        complaints: 200,
      },
    ],
  },
  FETCH_SOURCE_START: {
    headers: [
      {
        Header: "Info",
        columns: [
          {
            Header: "ID",
            accessor: "sr",
          },
          {
            Header: "Name",
            accessor: "sourceName",
          },
          {
            Header: "Source Details",
            accessor: "sourceDetails",
          },
        ],
      },
      {
        Header: "Count",
        columns: [
          {
            Header: "Complaints",
            accessor: "complaints",
          },
        ],
      },
    ],
    data: [
      {
        name: "First Source",
        detail: "This is source detail",
        complaints: 20,
      },
      {
        name: "Mushahid",
        detail: "This is another source detail",
        complaints: 300,
      },
    ],
  },
  FETCH_PROVINCE_START: {
    headers: [
      {
        Header: "Info",
        columns: [
          {
            Header: "ID",
            accessor: "sr",
          },
          {
            Header: "Name",
            accessor: "provinceName",
          },
          {
            Header: "Province Details",
            accessor: "provinceDetails",
          },
        ],
      },
      {
        Header: "Count",
        columns: [
          {
            Header: "Complaints",
            accessor: "complaints",
          },
        ],
      },
    ],
    data: [
      {
        name: "First Province",
        detail: "This is Province detail",
        complaints: 20,
      },
      {
        name: "Mushahid",
        detail: "This is another Province detail",
        complaints: 300,
      },
      {
        name: "First Province",
        detail: "This is Province detail",
        complaints: 14,
      },
      {
        name: "Mushahid",
        detail: "This is another Province detail",
        complaints: 20,
      },
      {
        name: "First Province",
        detail: "This is Province detail",
        complaints: 500,
      },
      {
        name: "Mushahid",
        detail: "This is another Province detail",
        complaints: 100,
      },
      {
        name: "First Province",
        detail: "This is Province detail",
        complaints: 600,
      },
      {
        name: "Mushahid",
        detail: "This is another Province detail",
        complaints: 200,
      },
      {
        name: "First Province",
        detail: "This is Province detail",
        complaints: 802,
      },
      {
        name: "Mushahid",
        detail: "This is another Province detail",
        complaints: 200,
      },
    ],
  },
  FETCH_UC_START: {
    headers: [
      {
        Header: "Info",
        columns: [
          {
            Header: "ID",
            accessor: "sr",
          },
          {
            Header: "Name",
            accessor: "ucName",
          },
          {
            Header: "UC Details",
            accessor: "ucDetails",
          },
        ],
      },
      {
        Header: "Count",
        columns: [
          {
            Header: "Complaints",
            accessor: "complaints",
          },
        ],
      },
    ],
    data: [
      {
        name: "First Province",
        detail: "This is Province detail",
        complaints: 20,
      },
      {
        name: "Mushahid",
        detail: "This is another Province detail",
        complaints: 300,
      },
      {
        name: "First Province",
        detail: "This is Province detail",
        complaints: 14,
      },
      {
        name: "Mushahid",
        detail: "This is another Province detail",
        complaints: 20,
      },
      {
        name: "First Province",
        detail: "This is Province detail",
        complaints: 500,
      },
      {
        name: "Mushahid",
        detail: "This is another Province detail",
        complaints: 100,
      },
      {
        name: "First Province",
        detail: "This is Province detail",
        complaints: 600,
      },
      {
        name: "Mushahid",
        detail: "This is another Province detail",
        complaints: 200,
      },
      {
        name: "First Province",
        detail: "This is Province detail",
        complaints: 802,
      },
      {
        name: "Mushahid",
        detail: "This is another Province detail",
        complaints: 200,
      },
    ],
  },
  FETCH_TEHSIL_START: {
    headers: [
      {
        Header: "Info",
        columns: [
          {
            Header: "ID",
            accessor: "sr",
          },
          {
            Header: "Name",
            accessor: "tehsilName",
          },
          {
            Header: "Tehsil Details",
            accessor: "tehsilDetails",
          },
        ],
      },
      {
        Header: "Count",
        columns: [
          {
            Header: "Complaints",
            accessor: "complaints",
          },
        ],
      },
    ],
    data: [
      {
        name: "First Province",
        detail: "This is Province detail",
        complaints: 20,
      },
      {
        name: "Mushahid",
        detail: "This is another Province detail",
        complaints: 300,
      },
      {
        name: "First Province",
        detail: "This is Province detail",
        complaints: 14,
      },
      {
        name: "Mushahid",
        detail: "This is another Province detail",
        complaints: 20,
      },
      {
        name: "First Province",
        detail: "This is Province detail",
        complaints: 500,
      },
      {
        name: "Mushahid",
        detail: "This is another Province detail",
        complaints: 100,
      },
      {
        name: "First Province",
        detail: "This is Province detail",
        complaints: 600,
      },
      {
        name: "Mushahid",
        detail: "This is another Province detail",
        complaints: 200,
      },
      {
        name: "First Province",
        detail: "This is Province detail",
        complaints: 802,
      },
      {
        name: "Mushahid",
        detail: "This is another Province detail",
        complaints: 200,
      },
    ],
  },
  FETCH_VILLAGE_START: {
    headers: [
      {
        Header: "Info",
        columns: [
          {
            Header: "ID",
            accessor: "sr",
          },
          {
            Header: "Name",
            accessor: "villageName",
          },
          {
            Header: "Village Details",
            accessor: "villageDetails",
          },
        ],
      },
      {
        Header: "Count",
        columns: [
          {
            Header: "Complaints",
            accessor: "complaints",
          },
        ],
      },
    ],
    data: [
      {
        name: "First Province",
        detail: "This is Province detail",
        complaints: 20,
      },
      {
        name: "Mushahid",
        detail: "This is another Province detail",
        complaints: 300,
      },
      {
        name: "First Province",
        detail: "This is Province detail",
        complaints: 14,
      },
      {
        name: "Mushahid",
        detail: "This is another Province detail",
        complaints: 20,
      },
      {
        name: "First Province",
        detail: "This is Province detail",
        complaints: 500,
      },
      {
        name: "Mushahid",
        detail: "This is another Province detail",
        complaints: 100,
      },
      {
        name: "First Province",
        detail: "This is Province detail",
        complaints: 600,
      },
      {
        name: "Mushahid",
        detail: "This is another Province detail",
        complaints: 200,
      },
      {
        name: "First Province",
        detail: "This is Province detail",
        complaints: 802,
      },
      {
        name: "Mushahid",
        detail: "This is another Province detail",
        complaints: 200,
      },
    ],
  },
  FETCH_DISTRICT_START: {
    headers: [
      {
        Header: "Info",
        columns: [
          {
            Header: "ID",
            accessor: "sr",
          },
          {
            Header: "Name",
            accessor: "districtName",
          },
          {
            Header: "District Details",
            accessor: "districtDetails",
          },
        ],
      },
      {
        Header: "Count",
        columns: [
          {
            Header: "Complaints",
            accessor: "complaints",
          },
        ],
      },
    ],
    data: [
      {
        name: "First District",
        detail: "This is District detail",
        complaints: 20,
      },
      {
        name: "Mushahid",
        detail: "This is another District detail",
        complaints: 300,
      },
      {
        name: "First District",
        detail: "This is District detail",
        complaints: 14,
      },
      {
        name: "Mushahid",
        detail: "This is another District detail",
        complaints: 20,
      },
      {
        name: "First District",
        detail: "This is District detail",
        complaints: 500,
      },
      {
        name: "Mushahid",
        detail: "This is another District detail",
        complaints: 100,
      },
      {
        name: "First District",
        detail: "This is District detail",
        complaints: 600,
      },
      {
        name: "Mushahid",
        detail: "This is another District detail",
        complaints: 200,
      },
      {
        name: "First District",
        detail: "This is District detail",
        complaints: 802,
      },
      {
        name: "Mushahid",
        detail: "This is another District detail",
        complaints: 200,
      },
    ],
  },
  FETCH_TAG_START: {
    headers: [
      {
        Header: "Info",
        columns: [
          {
            Header: "ID",
            accessor: "sr",
          },
          {
            Header: "Name",
            accessor: "tagName",
          },
          {
            Header: "Tag Details",
            accessor: "tagDetails",
          },
        ],
      },
      {
        Header: "Count",
        columns: [
          {
            Header: "Complaints",
            accessor: "complaints",
          },
        ],
      },
    ],
    data: [
      {
        name: "First Tag",
        detail: "This is Tag detail",
        complaints: 20,
      },
    ],
  },
  FETCH_USER_START: {
    headers: [
      {
        Header: "Info",
        columns: [
          {
            Header: "ID",
            accessor: "sr",
          },
          {
            Header: "Name",
            accessor: "displayName",
          },
          {
            Header: "Tag Details",
            accessor: "email",
          },
        ],
      },
      {
        Header: "Count",
        columns: [
          {
            Header: "Complaints",
            accessor: "complaints",
          },
        ],
      },
    ],
    data: [
      {
        name: "First Tag",
        detail: "This is Tag detail",
        complaints: 20,
      },
    ],
  },
  FETCH_PERMISSION_START: {
    headers: [
      {
        Header: "Info",
        columns: [
          {
            Header: "ID",
            accessor: "sr",
          },
          {
            Header: "Name",
            accessor: "permissionName",
          },
          {
            Header: "Permission Details",
            accessor: "permissionDetails",
          },
        ],
      },
      {
        Header: "Count",
        columns: [
          {
            Header: "Complaints",
            accessor: "complaints",
          },
        ],
      },
    ],
    data: [
      {
        name: "First Tag",
        detail: "This is Tag detail",
        complaints: 20,
      },
    ],
  },
  SEARCH_COLLECTION_START: {
    data: [],
    headers: [],
  },
};
export const search = {
  COMPLAINT: data.FETCH_COMPLAINT_START,
  HOME: data.FETCH_HOME_START,
  COMMENT: data.FETCH_COMMENT_START,
  NOTIFICATION: data.FETCH_NOTIFICATION_START,
  REPORT: data.FETCH_REPORT_START,
  PARTNER: data.FETCH_PARTNER_START,
  PROGRAM: data.FETCH_PROGRAM_START,
  PROJECT: data.FETCH_PROJECT_START,
  DOCUMENT: data.FETCH_DOCUMENT_START,
  CATEGORY: data.FETCH_CATEGORY_START,
  TYPE: data.FETCH_TYPE_START,
  STATUS: data.FETCH_STATUS_START,
  SOURCE: data.FETCH_SOURCE_START,
  PROVINCE: data.FETCH_PROVINCE_START,
  UC: data.FETCH_UC_START,
  TEHSIL: data.FETCH_TEHSIL_START,
  VILLAGE: data.FETCH_VILLAGE_START,
  DISTRICT: data.FETCH_DISTRICT_START,
  TAG: data.FETCH_TAG_START,
  USER: data.FETCH_USER_START,
  PERMISSION: data.FETCH_PERMISSION_START,
};
