import { CollectionActionTypes, ResourceTypes } from "./collection.types";

const INITIAL_STATE = {
  collections: [],
  filteredCollections: [],
  comments: null,
  chartdata: null,
  isFetching: false,
  isSearching: false,
  isReplying: true,
  isSaving: false,
  errorMessage: undefined,
  isCollectionHidden: false,
  isNewTabHidden: true,
  resource: null,
  context: null,
  formData: null,
  recordDetail: null,
  toasterMessage: null,
  dropDownOptions: [],
  filterFormData: { startDate: "", endDate: "" },
};

const collectionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CollectionActionTypes.SET_FILTER:
      return {
        ...state,
        filterFormData: {
          ...state.filterFormData,
          [action.payload.name]: action.payload.value,
        },
      };
    case CollectionActionTypes.RESET_FILTER:
      return {
        ...state,
        filterFormData: { startDate: "", endDate: "" },
      };
    case CollectionActionTypes.SHOW_NEW_TAB:
      return {
        ...state,
        formData: null,
        toasterMessage: null,
        isNewTabHidden: false,
        isCollectionHidden: true,
      };
    case CollectionActionTypes.REMOVE_TOASTER_MESSAGE:
      return {
        ...state,
        toasterMessage: null,
      };
    case CollectionActionTypes.SHOW_REPLY:
      return {
        ...state,
        isReplying: !state.isReplying,
      };
    case CollectionActionTypes.SHOW_EDIT_TAB:
      return {
        ...state,
        toasterMessage: null,
        formData: action.payload,
        isNewTabHidden: false,
        isCollectionHidden: true,
      };
    case CollectionActionTypes.SHOW_PROFILE_DETAIL:
      return {
        ...state,
        recordDetail: action.payload,
      };
    case CollectionActionTypes.FETCH_DETAIL_SUCCESS:
      return {
        ...state,
        recordDetail: action.payload,
      };
    case CollectionActionTypes.FETCH_DETAIL_FAILURE:
      return {
        ...state,
        toasterMessage: action.payload,
      };
    case CollectionActionTypes.SHOW_COLLECTION_TAB:
      return {
        ...state,
        isNewTabHidden: true,
        isCollectionHidden: false,
      };
    case CollectionActionTypes.FETCH_COLLECTIONS_START:
      return {
        ...state,
        isFetching: true,
      };
    case CollectionActionTypes.FETCH_COMPLAINT_START:
      return {
        ...state,
        isFetching: true,
        resource: ResourceTypes.COMPLAINT,
      };
    case CollectionActionTypes.FETCH_NOTIFICATION_START:
      return {
        ...state,
        isFetching: true,
        resource: ResourceTypes.NOTIFICATION,
      };
    case CollectionActionTypes.FETCH_PARTNER_START:
      return {
        ...state,
        isFetching: true,
        resource: ResourceTypes.PARTNER,
      };
    case CollectionActionTypes.FETCH_PROGRAM_START:
      return {
        ...state,
        isFetching: true,
        resource: ResourceTypes.PROGRAM,
      };
    case CollectionActionTypes.FETCH_PROJECT_START:
      return {
        ...state,
        isFetching: true,
        resource: ResourceTypes.PROJECT,
      };
    case CollectionActionTypes.FETCH_USER_START:
      return {
        ...state,
        isFetching: true,
        resource: ResourceTypes.USER,
      };
    case CollectionActionTypes.FETCH_PERMISSION_START:
      return {
        ...state,
        isFetching: true,
        resource: ResourceTypes.PERMISSION,
      };
    case CollectionActionTypes.FETCH_DOCUMENT_START:
      return {
        ...state,
        isFetching: true,
        resource: ResourceTypes.DOCUMENT,
      };
    case CollectionActionTypes.FETCH_CATEGORY_START:
      return {
        ...state,
        isFetching: true,
        resource: ResourceTypes.CATEGORY,
      };
    case CollectionActionTypes.FETCH_COMPLAINT_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        recordDetail: action.payload.results,
      };
    case CollectionActionTypes.FETCH_TYPE_START:
      return {
        ...state,
        isFetching: true,
        resource: ResourceTypes.TYPE,
      };
    case CollectionActionTypes.FETCH_STATUS_START:
      return {
        ...state,
        isFetching: true,
        resource: ResourceTypes.STATUS,
      };
    case CollectionActionTypes.FETCH_SOURCE_START:
      return {
        ...state,
        isFetching: true,
        resource: ResourceTypes.SOURCE,
      };
    case CollectionActionTypes.FETCH_PROVINCE_START:
      return {
        ...state,
        isFetching: true,
        resource: ResourceTypes.PROVINCE,
      };
    case CollectionActionTypes.FETCH_UC_START:
      return {
        ...state,
        isFetching: true,
        resource: ResourceTypes.UC,
      };
    case CollectionActionTypes.FETCH_TEHSIL_START:
      return {
        ...state,
        isFetching: true,
        resource: ResourceTypes.TEHSIL,
      };
    case CollectionActionTypes.FETCH_VILLAGE_START:
      return {
        ...state,
        isFetching: true,
        resource: ResourceTypes.VILLAGE,
      };
    case CollectionActionTypes.FETCH_DISTRICT_START:
      return {
        ...state,
        isFetching: true,
        resource: ResourceTypes.DISTRICT,
      };
    case CollectionActionTypes.FETCH_TAG_START:
      return {
        ...state,
        isFetching: true,
        resource: ResourceTypes.TAG,
      };
    case CollectionActionTypes.SEARCH_COLLECTION_START:
      return {
        ...state,
        isFetching: true,
        isSearching: true,
        searchType: action.payload.searchType,
      };
    case CollectionActionTypes.FETCH_REPORT_START:
      return {
        ...state,
        isFetching: true,
        resource: ResourceTypes.REPORT,
      };
    case CollectionActionTypes.FETCH_HOME_START:
      return {
        ...state,
        resource: ResourceTypes.HOME,
      };
    case CollectionActionTypes.SEARCH_COLLECTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSearching: false,
        collections: action.payload.collections,
        chartdata: action.payload.chartdata,
        filteredCollections: action.payload.filteredCollections,
        headers: action.payload.headers,
        formData: null,
        isNewTabHidden: true,
        isCollectionHidden: false,
      };
    case CollectionActionTypes.FETCH_COLLECTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        collections: action.payload.collections,
        chartdata: action.payload.chartdata,
        filteredCollections: action.payload.collections,
        headers: action.payload.headers,
        formData: null,
        isNewTabHidden: true,
        isCollectionHidden: false,
      };
    case CollectionActionTypes.FETCH_COMMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isReplying: false,
        comments: action.payload.comments,
      };
    case CollectionActionTypes.FETCH_HOME_SUCCESS:
      return {
        ...state,
        isFetching: false,
        chartdata: action.payload.chartdata,
      };
    case CollectionActionTypes.FETCH_COLLECTIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
        isNewTabHidden: true,
        isCollectionHidden: false,
      };
    case CollectionActionTypes.SAVE_COLLECTION_START:
      return {
        ...state,
        toasterMessage: null,
        formData: action.payload.data,
        isSaving: true,
        isFetching: true,
      };
    case CollectionActionTypes.SAVE_COLLECTION_FAILURE:
      return {
        ...state,
        isSaving: false,
        isFetching: false,
        toasterMessage: action.payload,
      };
    case CollectionActionTypes.FETCH_DROPDOWN_OPTIONS:
      return {
        ...state,
        dropDownOptions: action.payload,
      };
    case CollectionActionTypes.SAVE_COLLECTION_SUCCESS:
      if (action.payload.result._id)
        state.collections.push(action.payload.result);
      return {
        ...state,
        isNewTabHidden: true,
        isCollectionHidden: false,
        toasterMessage: action.payload.message,
        isSaving: false,
        isFetching: false,
      };
    case CollectionActionTypes.SAVE_COMMENT_SUCCESS:
      state.comments.unshift(action.payload.message.result);
      return {
        ...state,
        isNewTabHidden: true,
        isCollectionHidden: false,
        isSaving: false,
        isReplying: false,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default collectionReducer;
