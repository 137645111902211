import { UploadActionTypes } from "./upload.types";
import { CollectionActionTypes } from "../collection/collection.types";

const INITIAL_STATE = {
  progress: 0,
  files: [],
};

const uploadReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UploadActionTypes.UPLOAD_REQUEST:
      return {
        ...state,
      };
    case UploadActionTypes.CHANGE_DOCUMENT:
      return {
        ...state,
        files: [...action.payload.files],
      };
    case UploadActionTypes.UPLOAD_REMOVE:
      return {
        ...state,
        files: state.files.filter((el) => el.file !== action.payload),
      };
    case CollectionActionTypes.SHOW_EDIT_TAB: {
      return {
        ...state,
        files: action.payload.files ? action.payload.files : [],
      };
    }
    case CollectionActionTypes.SHOW_NEW_TAB: {
      return {
        ...state,
        files: [],
      };
    }
    case UploadActionTypes.UPLOAD_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case UploadActionTypes.UPLOAD_SUCCESS:
      return {
        ...state,
        progress: 0,
        files: [
          ...state.files,
          {
            file: action.meta.file,
            name: action.meta.name,
            ext: action.meta.ext,
          },
        ],
      };
    default:
      return state;
  }
};

export default uploadReducer;
