import * as React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  uploadRequest,
  removeRequest,
  downloadRequest,
} from "../../redux/upload/upload.actions";
import {
  selectUploader,
  selectUploadedFiles,
} from "../../redux/upload/upload.selectors";

import { ProgressContainer, UploadedFileContainer } from "./uploader.styles";

class PhotoUploader extends React.Component {
  upload = (e) => {
    const [...file] = e.target.files || e.dataTransfer.files;
    const fileType =
      this.props.uploadType !== undefined ? this.props.uploadType : "photo";
    file.forEach((f) => {
      this.props.onUpload({ file: f, fileType });
    });
  };
  remove = (file) => {
    const { onRemove } = this.props;
    onRemove(file);
  };
  download = (file) => {
    const { onDownload } = this.props;
    onDownload(file);
  };
  render() {
    const { progress, files, className, type } = this.props;
    const uploadType = type ? type : "file";
    return (
      <input
        type={uploadType}
        className={className}
        multiple={true}
        onChange={this.upload}
      />
    );
  }
}
const mapStateToProps = createStructuredSelector({
  progress: selectUploader,
  files: selectUploadedFiles,
});
const mapDispatchToProps = (dispatch) => ({
  onUpload: ({ file, fileType }) => {
    dispatch(uploadRequest({ file, fileType }));
  },
  onRemove: (file) => {
    dispatch(removeRequest(file));
  },
  onDownload: (file) => {
    dispatch(downloadRequest(file));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(PhotoUploader);
