import { UploadActionTypes } from "./upload.types";
export const onDocumentChange = (payload) => ({
  type: UploadActionTypes.CHANGE_DOCUMENT,
  payload,
});
export const uploadRequest = ({ file, fileType }) => ({
  type: UploadActionTypes.UPLOAD_REQUEST,
  payload: file,
  fileType,
});
export const removeRequest = (file) => ({
  type: UploadActionTypes.UPLOAD_REMOVE,
  payload: file,
});
export const downloadRequest = (file) => ({
  type: UploadActionTypes.UPLOAD_DOWNLOAD,
  payload: file,
});
export const uploadProgress = (file, progress) => ({
  type: UploadActionTypes.UPLOAD_PROGRESS,
  payload: progress,
  meta: { file },
});
export const uploadSuccess = (file) => ({
  type: UploadActionTypes.UPLOAD_SUCCESS,
  meta: file.meta,
  fileType: file.fileType,
});
export const uploadFailure = (file, err) => ({
  type: UploadActionTypes.UPLOAD_FAILURE,
  payload: err,
  error: true,
  meta: { file },
});
