import React from "react";
import {Link} from 'react-router-dom';
import * as MaterialDesign from "react-icons/md";
import "./notifications.styles.scss";
import {Animated} from "react-animated-css";
const CountNotification = ({toggleSearching}) => {
  const SearchIcon = MaterialDesign["MdSearch"];
  const BellIcon = MaterialDesign["MdChatBubbleOutline"];
  const NotifyIcon = MaterialDesign["MdNotifications"];
  return (
    <ul className="gx-app-nav">
      <li>
        <Animated
          animationIn="bounceInDown"
          animationOut="fadeOut"
          isVisible={true}
          animationInDuration={1500}
        >
          <SearchIcon
            onClick={toggleSearching}
            className="icon icon-search-new"
          />
        </Animated>
      </li>
      <li>
        <Animated
          animationIn="bounceInDown"
          animationOut="fadeOut"
          isVisible={true}
          animationInDuration={2000}
        >
          <Link to="/notifications">
            <NotifyIcon className="icon icon-notification" />
          </Link>
          
        </Animated>
      </li>
      <li>
        <Animated
          animationIn="bounceInDown"
          animationOut="fadeOut"
          isVisible={true}
          animationInDuration={2500}
        >
          <BellIcon className="icon icon-chat-new" />
        </Animated>
      </li>
    </ul>
  );
};

export default CountNotification;
