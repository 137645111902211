import { CollectionActionTypes } from "./collection.types";

export const fetchCollectionsStart = () => ({
  type: CollectionActionTypes.FETCH_COLLECTIONS_START,
});
export const fetchUserStart = () => ({
  type: CollectionActionTypes.FETCH_USER_START,
});
export const fetchNotificationStart = () => ({
  type: CollectionActionTypes.FETCH_NOTIFICATION_START,
});
export const fetchPermissionStart = () => ({
  type: CollectionActionTypes.FETCH_PERMISSION_START,
});
export const fetchComplaintStart = (params) => ({
  type: CollectionActionTypes.FETCH_COMPLAINT_START,
  params: params,
});
export const fetchDetailStart = (payload) => ({
  type: CollectionActionTypes.FETCH_DETAIL_START,
  payload: payload,
});
export const fetchComplaintDetailStart = (payload) => ({
  type: CollectionActionTypes.FETCH_COMPLAINT_DETAIL_START,
  payload,
});
export const addComplaintStatus = (complaint, status) => ({
  type: CollectionActionTypes.ADD_COMPLAINT_STATUS_START,
  payload: { complaint, status },
});
export const fetchCommentStart = (params) => ({
  type: CollectionActionTypes.FETCH_COMMENT_START,
  payload: params,
});
export const fetchPartnerStart = () => ({
  type: CollectionActionTypes.FETCH_PARTNER_START,
});

export const fetchReportStart = () => ({
  type: CollectionActionTypes.FETCH_REPORT_START,
});
export const fetchHomeStart = () => ({
  type: CollectionActionTypes.FETCH_HOME_START,
});
export const fetchCategoryStart = () => ({
  type: CollectionActionTypes.FETCH_CATEGORY_START,
});
export const fetchDocumentStart = () => ({
  type: CollectionActionTypes.FETCH_DOCUMENT_START,
});
export const fetchTypeStart = () => ({
  type: CollectionActionTypes.FETCH_TYPE_START,
});
export const fetchProgramStart = () => ({
  type: CollectionActionTypes.FETCH_PROGRAM_START,
});
export const fetchProjectStart = () => ({
  type: CollectionActionTypes.FETCH_PROJECT_START,
});
export const fetchProvinceStart = () => ({
  type: CollectionActionTypes.FETCH_PROVINCE_START,
});
export const fetchUcStart = () => ({
  type: CollectionActionTypes.FETCH_UC_START,
});
export const fetchTehsilStart = () => ({
  type: CollectionActionTypes.FETCH_TEHSIL_START,
});
export const fetchVillageStart = () => ({
  type: CollectionActionTypes.FETCH_VILLAGE_START,
});
export const fetchStatusStart = () => ({
  type: CollectionActionTypes.FETCH_STATUS_START,
});
export const fetchSourceStart = () => ({
  type: CollectionActionTypes.FETCH_SOURCE_START,
});
export const fetchTagStart = () => ({
  type: CollectionActionTypes.FETCH_TAG_START,
});
export const fetchDistrictStart = () => ({
  type: CollectionActionTypes.FETCH_DISTRICT_START,
});
export const fetchCollectionsSuccess = (collectionsMap) => ({
  type: CollectionActionTypes.FETCH_COLLECTIONS_SUCCESS,
  payload: collectionsMap,
});
export const searchCollectionSuccess = (collectionsMap) => ({
  type: CollectionActionTypes.SEARCH_COLLECTION_SUCCESS,
  payload: collectionsMap,
});
export const fetchDetailSuccess = (collectionsMap) => ({
  type: CollectionActionTypes.FETCH_DETAIL_SUCCESS,
  payload: collectionsMap,
});
export const fetchDetailFailure = (errorMessage) => ({
  type: CollectionActionTypes.FETCH_DETAIL_FAILURE,
  payload: errorMessage,
});
export const fetchComplaintDetailSuccess = (collectionsMap) => ({
  type: CollectionActionTypes.FETCH_COMPLAINT_DETAIL_SUCCESS,
  payload: collectionsMap,
});
export const fetchComplaintDetailFailure = (errorMessage) => ({
  type: CollectionActionTypes.FETCH_COMPLAINT_DETAIL_FAILURE,
  payload: errorMessage,
});
export const fetchCollectionsFailure = (errorMessage) => ({
  type: CollectionActionTypes.FETCH_COLLECTIONS_FAILURE,
  payload: errorMessage,
});
export const showCollectionNewTab = () => ({
  type: CollectionActionTypes.SHOW_NEW_TAB,
});
export const showCollectionTab = () => ({
  type: CollectionActionTypes.SHOW_COLLECTION_TAB,
});
export const showEditTab = (item) => ({
  type: CollectionActionTypes.SHOW_EDIT_TAB,
  payload: item,
});
export const toggleReply = () => ({
  type: CollectionActionTypes.SHOW_REPLY,
});
export const showProfileDetails = (item) => ({
  type: CollectionActionTypes.SHOW_PROFILE_DETAIL,
  payload: item,
});
export const searchCollection = (searchItem, searchType, searchForm) => ({
  type: CollectionActionTypes.SEARCH_COLLECTION_START,
  payload: { searchItem, searchType, searchForm },
});
export const setFilterFormData = (payload) => ({
  type: CollectionActionTypes.SET_FILTER,
  payload,
});
export const resetFilter = () => ({
  type: CollectionActionTypes.RESET_FILTER,
});
export const saveCollectionStart = (formData) => ({
  type: CollectionActionTypes.SAVE_COLLECTION_START,
  payload: formData,
});
export const saveCommentStart = (formData) => ({
  type: CollectionActionTypes.SAVE_COMMENT_START,
  payload: formData,
});
export const removeToasterMessage = () => ({
  type: CollectionActionTypes.REMOVE_TOASTER_MESSAGE,
});
export const saveCollectionSuccess = (collectionsMap) => ({
  type: CollectionActionTypes.SAVE_COLLECTION_SUCCESS,
  payload: collectionsMap,
});
export const saveCommentSuccess = (collectionsMap) => ({
  type: CollectionActionTypes.SAVE_COMMENT_SUCCESS,
  payload: collectionsMap,
});
export const fetchCommentsSuccess = (collectionsMap) => ({
  type: CollectionActionTypes.FETCH_COMMENT_SUCCESS,
  payload: collectionsMap,
});
export const fetchHomeSuccess = (collectionsMap) => ({
  type: CollectionActionTypes.FETCH_HOME_SUCCESS,
  payload: collectionsMap,
});
export const saveCollectionFailure = (errorMessage) => ({
  type: CollectionActionTypes.SAVE_COLLECTION_FAILURE,
  payload: errorMessage,
});
export const fetchComplaintFormDropOptions = (collectionsMap) => ({
  type: CollectionActionTypes.FETCH_DROPDOWN_OPTIONS,
  payload: collectionsMap,
});
