import UserActionTypes from "./user.types";

export const googleSignInStart = () => ({
  type: UserActionTypes.GOOGLE_SIGN_IN_START,
});
export const changeProfileSuccess = ({ user, profile }) => ({
  type: UserActionTypes.CHANGE_PROFILE_SUCCESS,
  payload: user,
  profile,
});
export const changeProfileFailure = (error) => ({
  type: UserActionTypes.CHANGE_PROFILE_FAILURE,
  payload: error,
});
export const signInSuccess = (user) => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: user,
});

export const signInFailure = (error) => ({
  type: UserActionTypes.SIGN_IN_FAILURE,
  payload: error,
});

export const emailSignInStart = (emailAndPassword) => ({
  type: UserActionTypes.EMAIL_SIGN_IN_START,
  payload: emailAndPassword,
});

export const checkUserSession = () => ({
  type: UserActionTypes.CHECK_USER_SESSION,
});

export const signOutStart = () => ({
  type: UserActionTypes.SIGN_OUT_START,
});
export const profileDrop = () => ({
  type: UserActionTypes.PROFILE_DROP,
});

export const signOutSuccess = () => ({
  type: UserActionTypes.SIGN_OUT_SUCCESS,
});

export const signOutFailure = (error) => ({
  type: UserActionTypes.SIGN_OUT_FAILURE,
  payload: error,
});

export const signUpStart = (userCredentials) => ({
  type: UserActionTypes.SIGN_UP_START,
  payload: userCredentials,
});
export const loadUserPermission = (permissions) => ({
  type: UserActionTypes.LOAD_USER_PERMISSION,
  payload: { permissions },
});
export const saveUserPermissionStart = (
  nextTargetKeys,
  direction,
  moveKeys,
  id
) => ({
  type: UserActionTypes.SAVE_USER_PERMISSION_START,
  payload: {
    next: nextTargetKeys,
    direction: direction,
    moveKeys: moveKeys,
    id: id,
  },
});
export const handlePermissionChange = (
  sourceSelectedKeys,
  targetSelectedKeys
) => ({
  type: UserActionTypes.SELECT_USER_PERMISSION,
  payload: { sourceSelectedKeys, targetSelectedKeys },
});
export const signUpSuccess = ({ user, additionalData }) => ({
  type: UserActionTypes.SIGN_UP_SUCCESS,
  payload: { user, additionalData },
});

export const signUpFailure = (error) => ({
  type: UserActionTypes.SIGN_UP_FAILURE,
  payload: error,
});
export const removeToasterMessage = () => ({
  type: UserActionTypes.REMOVE_TOASTER_MESSAGE,
});
