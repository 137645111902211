import React from "react";
import { connect } from "react-redux";
const { links } = require("../../redux/api.links");
const Profile = ({ currentUser }) => {
  const handleIconError = (event) => {
    event.target.src = "./no-image.png";
  };
  return (
    <img
      src={links["BASE"] + "/upload?id=" + currentUser.profile.file}
      onError={handleIconError}
      alt="profile"
    />
  );
};
const mapState = (state) => {
  const { user } = state;
  return {
    currentUser: user.currentUser,
  };
};
export default connect(mapState)(Profile);
