import { createSelector } from "reselect";

const selectCollection = (state) => state.collection;
const selectHeaders = (state) => state.collection.headers;
export const selectFormData = createSelector(
  [selectCollection],
  (collection) => collection.formData
);
export const selectAppContext = createSelector(
  [selectCollection],
  (context) => context
);
export const selectCollections = createSelector(
  [selectCollection],
  (collection) => collection.collections
);
export const selectComments = createSelector(
  [selectCollection],
  (collection) => collection.comments
);
export const selectDetail = createSelector(
  [selectCollection],
  (collection) => collection.recordDetail
);
export const selectCollectionHidden = createSelector(
  [selectCollection],
  (collection) => collection.isNewTabHidden
);
export const selectCollectionsForPreview = createSelector(
  [selectCollections],
  (collection) => collection
);
export const selectToasterMessage = createSelector(
  [selectCollection],
  (collection) => collection.toasterMessage
);
export const selectFilteredCollectionsForPreview = createSelector(
  [selectCollection],
  (collection) => collection.filteredCollections
);
export const selectCollectionItemDetail = createSelector(
  [selectCollection],
  (collection) => collection.collections
);
export const selectCollectionsForChart = createSelector(
  [selectCollection],
  (collection) => collection.chartdata
);
export const selectHeadersForPreview = createSelector(
  [selectHeaders],
  (headers) => headers
);
export const selectCollectionData = (collectionUrlParam) =>
  createSelector([selectCollections], (collections) =>
    collections ? collections[collectionUrlParam] : null
  );

export const selectIsCollectionFetching = createSelector(
  [selectCollection],
  (collection) => collection.isFetching
);
export const selectIsReplyaing = createSelector(
  [selectCollection],
  (collection) => collection.isReplying
);
export const selectIsSearching = createSelector(
  [selectCollection],
  (collection) => collection.isSearching
);
export const selectResouceType = createSelector(
  [selectCollection],
  (collection) => collection.resource
);
export const selectFilterFormData = createSelector(
  [selectCollection],
  (collection) => collection.filterFormData
);
export const selectIsCollectionsLoaded = createSelector(
  [selectCollection],
  (collection) => !!collection.collections
);

export const selectDropDownOptions = createSelector(
  [selectCollection],
  (collection) => collection.dropDownOptions
);
