import {
  select,
  takeLatest,
  put,
  all,
  call,
  takeEvery,
} from "redux-saga/effects";
import { selectCurrentUser } from "../user/user.selectors";
import UserActionTypes from "./user.types";

import {
  signInSuccess,
  signInFailure,
  signOutSuccess,
  signOutFailure,
  signUpSuccess,
  signUpFailure,
  changeProfileSuccess,
  changeProfileFailure,
} from "./user.actions";

import {
  auth,
  googleProvider,
  createUserProfileDocument,
  getCurrentUser,
} from "../../firebase/firebase.utils";

export function* getSnapshotFromUserAuth(userAuth, additionalData) {
  try {
    /*
    const userRef = yield call(
      createUserProfileDocument,
      userAuth,
      additionalData
    );
    const userSnapshot = yield userRef.get();
    yield put(signInSuccess({ id: userSnapshot.id, ...userSnapshot.data() }));
    */
    if (userAuth._id)
      yield put(signInSuccess({ id: userAuth._id, ...userAuth }));
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* signInWithGoogle() {
  try {
    const { user } = yield auth.signInWithPopup(googleProvider);
    yield getSnapshotFromUserAuth(user);
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* signInWithEmail({ payload: { email, password } }) {
  const { links } = require("../api.links");
  try {
    //const { user } = yield auth.signInWithEmailAndPassword(email, password);
    const payload = { email, password };
    const url = links["SIGN_IN"];
    const user = yield fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then((response) => response.json());
    if (user.status > 205) {
      yield put(signInFailure(user));
    } else {
      yield getSnapshotFromUserAuth(user);
    }
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* isUserAuthenticated() {
  try {
    const userAuth = yield getCurrentUser();
    if (!userAuth) return;
    yield getSnapshotFromUserAuth(userAuth);
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* signOut() {
  try {
    yield auth.signOut();
    yield put(signOutSuccess());
  } catch (error) {
    yield put(signOutFailure(error));
  }
}

export function* signUp({ payload: { email, password, displayName } }) {
  const payload = { email, password, displayName };
  const { links } = require("../api.links");
  try {
    //const { user } = yield auth.createUserWithEmailAndPassword(email, password);
    const url = links["SIGN_UP"];
    const user = yield fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    yield put(signUpSuccess({ user, additionalData: { displayName } }));
  } catch (error) {
    yield put(signUpFailure(error));
  }
}

export function* onChangeProfileAsync(action) {
  if (action.fileType === "ProfilePicture") {
    const payload = {
      ext: action.meta.ext,
      file: action.meta.file,
      name: action.meta.name,
    };
    const { links } = require("../api.links");
    try {
      const user = yield select(selectCurrentUser);
      const url = links["CHANGE_PROFILE"];
      const response = yield fetch(url, {
        method: "POST",
        headers: {
          AuthenticationToken: user.accessToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      yield put(changeProfileSuccess({ user: response, profile: payload }));
    } catch (error) {
      yield put(changeProfileFailure(error));
    }
  }
}

export function* signInAfterSignUp({ payload: { user, additionalData } }) {
  //yield getSnapshotFromUserAuth(user, additionalData);
}

export function* onGoogleSignInStart() {
  yield takeLatest(UserActionTypes.GOOGLE_SIGN_IN_START, signInWithGoogle);
}

export function* onEmailSignInStart() {
  yield takeLatest(UserActionTypes.EMAIL_SIGN_IN_START, signInWithEmail);
}

export function* onCheckUserSession() {
  yield takeLatest(UserActionTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* onSignOutStart() {
  yield takeLatest(UserActionTypes.SIGN_OUT_START, signOut);
}

export function* onSignUpStart() {
  yield takeLatest(UserActionTypes.SIGN_UP_START, signUp);
}

export function* onSignUpSuccess() {
  yield takeLatest(UserActionTypes.SIGN_UP_SUCCESS, signInAfterSignUp);
}
export function* saveUserPermissionAsync(action) {
  const { links } = require("../api.links");
  try {
    const url = links[action.type];
    const response = yield fetch(url, {
      method: "POST",
      headers: {
        AuthenticationToken: "19d01592-ec4e-433a-9456-443373675952",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: action.payload.id,
        permission: action.payload.moveKeys,
        direction: action.payload.direction,
      }),
    }).then((response) => response.json());
    yield console.log(response);
    //yield put(fetchHomeSuccess(response));
  } catch (error) {
    //yield put(fetchCollectionsFailure(error.message));
  }
}
export function* onSaveUserPermissionStart() {
  yield takeLatest(
    UserActionTypes.SAVE_USER_PERMISSION_START,
    saveUserPermissionAsync
  );
}
export function* onChangeProfile() {
  yield takeEvery("UPLOAD_SUCCESS", onChangeProfileAsync);
}
export function* userSagas() {
  yield all([
    call(onGoogleSignInStart),
    call(onEmailSignInStart),
    call(isUserAuthenticated),
    call(onSignOutStart),
    call(onSignUpStart),
    call(onSignUpSuccess),
    call(onSaveUserPermissionStart),
    call(onChangeProfile),
  ]);
}
