import { call, put, take, takeEvery, all } from "redux-saga/effects";
import { UploadActionTypes } from "./upload.types";
//ActionTypes, uploadProgress, uploadSuccess, uploadFailure
import { createUploadFileChannel } from "./upload.channels";

import { uploadFailure, uploadSuccess, uploadProgress } from "./upload.actions";
// Watch for an upload request and then
// defer to another saga to perform the actual upload
export function* uploadRequestWatcherSaga() {
  yield takeEvery(UploadActionTypes.UPLOAD_REQUEST, function* (action) {
    const file = action.payload;
    const fileType = action.fileType;
    yield call(uploadFileSaga, { file, fileType });
  });
}
// Upload the specified file
export function* uploadFileSaga({ file, fileType }) {
  const { links } = require("../api.links");
  const uploadUrl = links["UPLOAD"];
  const channel = yield call(createUploadFileChannel, uploadUrl, file);
  while (true) {
    const { progress = 0, err, success, response } = yield take(channel);
    if (err) {
      yield put(uploadFailure(file, err));
      return;
    }
    if (success) {
      yield put(uploadSuccess({ meta: JSON.parse(response), fileType }));
    }
    yield put(uploadProgress(file, progress));
  }
}
export function* downloadRequestAsync(action) {
  const { links } = require("../api.links");
  const uploadUrl = links["UPLOAD"];
  var FileSaver = require("file-saver");
  yield FileSaver.saveAs(
    uploadUrl + "?id=" + action.payload.file,
    action.payload.name
  );
}
export function* downloadRequest(file) {
  yield takeEvery(UploadActionTypes.UPLOAD_DOWNLOAD, downloadRequestAsync);
}
export function* uploadSagas() {
  yield all([call(uploadRequestWatcherSaga), call(downloadRequest)]);
}
