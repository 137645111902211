export const CollectionActionTypes = {
  ONLINE_COMPLAINT_COMPLETE: "ONLINE_COMPLAINT_COMPLETE",
  FETCH_HOME_START: "FETCH_HOME_START",
  FETCH_NOTIFICATION_START: "FETCH_NOTIFICATION_START",
  FETCH_DETAIL_START: "FETCH_DETAIL_START",
  FETCH_COMPLAINT_DETAIL_START: "FETCH_COMPLAINT_DETAIL_START",
  FETCH_COMPLAINT_START: "FETCH_COMPLAINT_START",
  FETCH_COMMENT_START: "FETCH_COMMENT_START",
  FETCH_REPORT_START: "FETCH_REPORT_START",
  FETCH_PARTNER_START: "FETCH_PARTNER_START",
  FETCH_PROGRAM_START: "FETCH_PROGRAM_START",
  FETCH_PROJECT_START: "FETCH_PROJECT_START",
  FETCH_CATEGORY_START: "FETCH_CATEGORY_START",
  FETCH_DOCUMENT_START: "FETCH_DOCUMENT_START",
  FETCH_TYPE_START: "FETCH_TYPE_START",
  FETCH_STATUS_START: "FETCH_STATUS_START",
  FETCH_SOURCE_START: "FETCH_SOURCE_START",
  FETCH_PROVINCE_START: "FETCH_PROVINCE_START",
  FETCH_DISTRICT_START: "FETCH_DISTRICT_START",
  FETCH_TAG_START: "FETCH_TAG_START",
  FETCH_USER_START: "FETCH_USER_START",
  FETCH_PERMISSION_START: "FETCH_PERMISSION_START",
  FETCH_UC_START: "FETCH_UC_START",
  FETCH_TEHSIL_START: "FETCH_TEHSIL_START",
  FETCH_VILLAGE_START: "FETCH_VILLAGE_START",
  FETCH_COMPLAINT_TYPE_START: "FETCH_COMPLAINT_TYPE_START",

  FETCH_DROPDOWN_OPTIONS: "FETCH_DROPDOWN_OPTIONS",

  FETCH_COLLECTIONS_START: "FETCH_COLLECTIONS_START",
  FETCH_COLLECTIONS_SUCCESS: "FETCH_COLLECTIONS_SUCCESS",
  FETCH_COMMENT_SUCCESS: "FETCH_COMMENT_SUCCESS",
  FETCH_DETAIL_SUCCESS: "FETCH_DETAIL_SUCCESS",
  FETCH_COMPLAINT_DETAIL_SUCCESS: "FETCH_COMPLAINT_DETAIL_SUCCESS",
  FETCH_COMPLAINT_DETAIL_FAILURE: "FETCH_COMPLAINT_DETAIL_FAILURE",
  FETCH_DETAIL_FAILURE: "FETCH_DETAIL_FAILURE",
  FETCH_COLLECTIONS_FAILURE: "FETCH_COLLECTIONS_FAILURE",
  FETCH_HOME_SUCCESS: "FETCH_HOME_SUCCESS",

  SHOW_NEW_TAB: "SHOW_NEW_TAB",
  SHOW_EDIT_TAB: "SHOW_EDIT_TAB",
  SHOW_COLLECTION_TAB: "SHOW_COLLECTION_TAB",
  SHOW_PROFILE_DETAIL: "SHOW_PROFILE_DETAIL",
  SHOW_REPLY: "SHOW_REPLY",
  SEARCH_COLLECTION_START: "SEARCH_COLLECTION_START",
  SEARCH_COLLECTION_SUCCESS: "SEARCH_COLLECTION_SUCCESS",
  SEARCH_COLLECTION_FAILURE: "SEARCH_COLLECTION_FAILURE",
  SAVE_COLLECTION_START: "SAVE_COLLECTION_START",
  SAVE_COMMENT_START: "SAVE_COMMENT_START",
  SAVE_COMMENT_SUCCESS: "SAVE_COMMENT_SUCCESS",
  SAVE_COLLECTION_SUCCESS: "SAVE_COLLECTION_SUCCESS",
  SAVE_COLLECTION_FAILURE: "SAVE_COLLECTION_FAILURE",
  UPDATE_COLLECTION_START: "UPDATE_COLLECTION_START",
  UPDATE_COLLECTION_SUCCESS: "UPDATE_COLLECTION_SUCCESS",
  UPDATE_COLLECTION_FAILURE: "UPDATE_COLLECTION_FAILURE",
  REMOVE_TOASTER_MESSAGE: "REMOVE_TOASTER_MESSAGE",
  ADD_COMPLAINT_STATUS_START: "ADD_COMPLAINT_STATUS_START",
  SET_FILTER: "SET_FILTER",
  RESET_FILTER: "RESET_FILTER",
};
export const ResourceTypes = {
  HOME: { type: "HOME" },
  NOTIFICATION: { type: "NOTIFICATION" },
  COMPLAINT_TYPE: { type: "TYPE" },
  COMPLAINT: {
    type: "COMPLAINT",
    actions: [
      { id: 1, label: "New Complaint", icon: "MdAdd", url: "/complaint-new" },
    ],
    paths: [
      { id: 1, label: "Complaints", icon: "MdPanTool", url: "/complaints" },
    ],
  },
  REPORT: { type: "REPORT" },
  USER: {
    type: "USER",
    actions: [{ id: 1, label: "New User", icon: "MdAdd", url: "/user-new" }],
    paths: [{ id: 1, label: "Users", icon: "MdAccountCircle", url: "/users" }],
  },
  PERMISSION: {
    type: "PERMISSION",
    actions: [
      { id: 1, label: "New Permission", icon: "MdAdd", url: "/parmission-new" },
    ],
    paths: [
      { id: 1, label: "Permissions", icon: "MdExtension", url: "/permissions" },
    ],
  },
  PARTNER: {
    type: "PARTNER",
    actions: [
      { id: 1, label: "New Partner", icon: "MdAdd", url: "/partner-new" },
    ],
    paths: [
      { id: 1, label: "Partners", icon: "MdGroupWork", url: "/partners" },
    ],
  },
  PROGRAM: {
    type: "PROGRAM",
    actions: [
      { id: 1, label: "New Program", icon: "MdAdd", url: "/program-new" },
    ],
    paths: [
      {
        id: 1,
        label: "Programs",
        icon: "MdChromeReaderMode",
        url: "/programs",
      },
    ],
  },
  PROJECT: {
    type: "PROJECT",
    actions: [
      { id: 1, label: "New Project", icon: "MdAdd", url: "/project-new" },
    ],
    paths: [
      { id: 1, label: "Projects", icon: "MdDashboard", url: "/projects" },
    ],
  },
  DOCUMENT: {
    type: "DOCUMENT",
    actions: [
      { id: 1, label: "New Document Type", icon: "MdAdd", url: "/partner-new" },
    ],
    paths: [
      {
        id: 1,
        label: "Document Types",
        icon: "MdSwapVerticalCircle",
        url: "/document",
      },
    ],
  },
  CATEGORY: {
    type: "CATEGORY",
    actions: [
      { id: 1, label: "New Category", icon: "MdAdd", url: "/partner-new" },
    ],
    paths: [
      {
        id: 1,
        label: "Categories",
        icon: "MdSwapVerticalCircle",
        url: "/category",
      },
    ],
  },
  TYPE: {
    type: "TYPE",
    actions: [{ id: 1, label: "New Type", icon: "MdAdd", url: "/partner-new" }],
    paths: [
      {
        id: 1,
        label: "Complaint Types",
        icon: "MdSwapVerticalCircle",
        url: "/type",
      },
    ],
  },
  STATUS: {
    type: "STATUS",
    actions: [
      { id: 1, label: "New Status", icon: "MdAdd", url: "/partner-new" },
    ],
    paths: [{ id: 1, label: "Status", icon: "MdInfo", url: "/status" }],
  },
  SOURCE: {
    type: "SOURCE",
    actions: [
      { id: 1, label: "New Source", icon: "MdAdd", url: "/source-new" },
    ],
    paths: [{ id: 1, label: "Source", icon: "MdInfo", url: "/source" }],
  },
  PROVINCE: {
    type: "PROVINCE",
    actions: [
      { id: 1, label: "New Province", icon: "MdAdd", url: "/partner-new" },
    ],
    paths: [
      { id: 1, label: "Provinces", icon: "MdLanguage", url: "/province" },
    ],
  },
  DISTRICT: {
    type: "DISTRICT",
    actions: [
      { id: 1, label: "New District", icon: "MdAdd", url: "/district-new" },
    ],
    paths: [
      { id: 1, label: "Districts", icon: "MdLocationOn", url: "/district" },
    ],
  },
  UC: {
    type: "UC",
    actions: [
      { id: 1, label: "New Union Council", icon: "MdAdd", url: "/uc-new" },
    ],
    paths: [{ id: 1, label: "Union Councils", icon: "MdLanguage", url: "/uc" }],
  },
  TEHSIL: {
    type: "TEHSIL",
    actions: [
      { id: 1, label: "New Tehsil", icon: "MdAdd", url: "/tehsil-new" },
    ],
    paths: [{ id: 1, label: "Tehsils", icon: "MdLanguage", url: "/tehsil" }],
  },
  VILLAGE: {
    type: "VILLAGE",
    actions: [
      { id: 1, label: "New Village", icon: "MdAdd", url: "/village-new" },
    ],
    paths: [{ id: 1, label: "Villages", icon: "MdLanguage", url: "/village" }],
  },
  TAG: {
    type: "TAG",
    actions: [{ id: 1, label: "New Tag", icon: "MdAdd", url: "/tag-new" }],
    paths: [{ id: 1, label: "Tags", icon: "MdTurnedIn", url: "/tag" }],
  },
};
