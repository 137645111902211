import React from "react";
import { connect } from "react-redux";

const Lookup = ({ data, lookup, accessor, type }) => {
  console.log(lookup[data.value]);
  const response = () => {
    switch (type) {
      case "nameFromKey":
        return lookup[data.value][accessor];
      case "formatDate":
        return data.value ? new Date(data.value).toLocaleDateString() : "none";
      default:
        return "loading";
    }
  };
  return (
    <span className="lo">
      {response()}
      {/* {lookup[data.value] ? lookup[data.value] : data.value} */}
    </span>
  );
};
const mapState = (state) => {
  const { lookup } = state;
  return {
    lookup,
  };
};
export default connect(mapState)(Lookup);
