import { createSelector } from "reselect";

const selectUser = (state) => state.user;

export const selectCurrentUser = createSelector(
  [selectUser],
  (user) => user.currentUser
);
export const selectProfileDrop = createSelector(
  [selectUser],
  (user) => user.profile
);
export const selectSelectedPermissions = createSelector(
  [selectUser],
  (user) => user.selectedPermissions
);
export const selectIsLoading = createSelector(
  [selectUser],
  (user) => user.isLoading
);
export const selectUserPermissions = createSelector(
  [selectUser],
  (user) => user.userPermissions
);
export const selectToasterMessage = createSelector(
  [selectUser],
  (user) => user.toasterMessage
);
