import React from 'react';
import {SearchInput} from './search.styles';
const Search = ({placeholder,onChange,className}) =>{
    
    return (
        <SearchInput className={className}>
            <input onChange={onChange} placeholder={placeholder} />
        </SearchInput>
    )
}
export default Search;