import {
  select,
  takeLatest,
  call,
  put,
  all,
  takeEvery,
} from "redux-saga/effects";
import { selectCurrentUser } from "../user/user.selectors";
import { selectCollections } from "../collection/collection.selectors";
import { notify } from "../../constants/helpers";
//import {convertCollectionsSnapshotToMap} from '../../api/api.utils';
import {
  fetchCollectionsSuccess,
  searchCollectionSuccess,
  fetchComplaintDetailSuccess,
  fetchComplaintDetailFailure,
  fetchCommentsSuccess,
  fetchHomeSuccess,
  fetchCollectionsFailure,
  fetchComplaintFormDropOptions,
  saveCollectionSuccess,
  saveCommentSuccess,
  saveCollectionFailure,
  fetchDetailSuccess,
  fetchDetailFailure,
} from "./collection.actions";

import { CollectionActionTypes } from "./collection.types";
export function* fecthHomeAsync(action) {
  const { links } = require("../api.links");
  try {
    const user = yield select(selectCurrentUser);
    const url = links[action.type];
    const json = yield fetch(url, {
      //method: 'GET',
      headers: {
        AuthenticationToken: user.accessToken,
        "Content-Type": "application/json",
      },
      //body: JSON.stringify({complaint:action.payload.data})
    }).then((response) => response.json());
    const response = { chartdata: json.results };
    const urlDrops = links["FETCH_COMPLAINT_DROPDOWNS"];
    const drops = yield fetch(urlDrops, {
      headers: {
        AuthenticationToken: user.accessToken,
        "Content-Type": "application/json",
      },
    }).then((response) => response.json());
    yield put(fetchComplaintFormDropOptions(drops.results));
    yield put(fetchHomeSuccess(response));
  } catch (error) {
    yield put(fetchCollectionsFailure(error.message));
  }
}
export function* fetchCollectionsAsync(action) {
  const { data } = require("./collection.data");
  const user = yield select(selectCurrentUser);
  const { links } = require("../api.links");
  const snapshot = data[action.type].data;
  const tableHeaders = data[action.type].headers;

  try {
    const url = links[action.type];
    const json = yield fetch(url, {
      //method: 'GET',
      headers: {
        AuthenticationToken: user.accessToken,
        "Content-Type": "application/json",
      },
      //body: JSON.stringify({complaint:action.payload.data})
    }).then((response) => response.json());
    const response = { collections: json.results, headers: tableHeaders };
    //if(action.type==='FETCH_COMPLAINT_START'){
    const urlDrops = links["FETCH_COMPLAINT_DROPDOWNS"];
    const drops = yield fetch(urlDrops, {
      headers: {
        AuthenticationToken: user.accessToken,
        "Content-Type": "application/json",
      },
    }).then((response) => response.json());
    yield put(fetchComplaintFormDropOptions(drops.results));
    //}
    yield put(fetchCollectionsSuccess(response));
  } catch (error) {
    yield put(fetchCollectionsFailure(error.message));
  }
}
export function* fetchCommentsAsync(action) {
  const { links } = require("../api.links");
  try {
    const user = yield select(selectCurrentUser);
    const url = links[action.type] + "?id=" + action.payload;
    const response = yield fetch(url, {
      //method: 'GET',
      headers: {
        AuthenticationToken: user.accessToken,
        "Content-Type": "application/json",
      },
      //body: JSON.stringify({complaint:action.payload.data})
    }).then((response) => response.json());
    yield put(fetchCommentsSuccess({ comments: response.results }));
  } catch (error) {
    yield put(fetchCollectionsFailure(error.message));
  }
}

export function* saveCollectionAsync(item) {
  try {
    const user = yield select(selectCurrentUser);
    const response = yield fetch(item.payload.url, {
      method: "POST",
      headers: {
        AuthenticationToken: user.accessToken,
        "Content-Type": "application/json",
        enctype: "multipart/form-data",
      },
      body: JSON.stringify(item.payload.data),
    }).then((response) => response.json());
    console.log(response);
    if (response.status > 205) {
      yield put(saveCollectionFailure(response));
    } else {
      yield put(saveCollectionSuccess(response));
    }
  } catch (error) {
    yield put(saveCollectionFailure({ message: error }));
  }
}
export function* addComplaintStatusAsync(action) {
  try {
    const user = yield select(selectCurrentUser);
    const { links } = require("../api.links");
    const url = links[action.type];
    const response = yield fetch(url, {
      method: "POST",
      headers: {
        AuthenticationToken: user.accessToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    }).then((response) => response.json());
    console.log("saved comment", response.result.savedComment);
    yield put(
      saveCommentSuccess({
        message: response.result.savedComment,
        data: action.payload,
      })
    );
  } catch (error) {
    yield put(saveCollectionFailure({ message: error }));
  }
}
export function* fetchDetailAsync(action) {
  try {
    const user = yield select(selectCurrentUser);
    const { links } = require("../api.links");
    const url = links[action.payload.type];
    const response = yield fetch(url, {
      method: "POST",
      headers: {
        AuthenticationToken: user.accessToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(action.payload),
    }).then((response) => response.json());
    yield put(fetchDetailSuccess(response));
  } catch (error) {
    yield put(fetchDetailFailure({ message: error }));
  }
}
export function* fetchComplaintDetilAsync(action) {
  try {
    const user = yield select(selectCurrentUser);
    const { links } = require("../api.links");
    const url = links["BASE"] + "/complaint/details?id=" + action.payload;
    const response = yield fetch(url, {
      method: "GET",
      headers: {
        AuthenticationToken: user.accessToken,
        "Content-Type": "application/json",
      },
      //body: JSON.stringify(action.payload),
    }).then((response) => response.json());
    yield put(fetchComplaintDetailSuccess(response));
  } catch (error) {
    yield put(fetchComplaintDetailFailure({ message: error }));
  }
}
export function* saveCommentAsync(item) {
  try {
    const user = yield select(selectCurrentUser);
    const response = yield fetch(item.payload.url, {
      method: "POST",
      headers: {
        AuthenticationToken: user.accessToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item.payload.data),
    }).then((response) => response.json());
    yield put(
      saveCommentSuccess({ message: response, data: item.payload.data })
    );
  } catch (error) {
    yield put(saveCollectionFailure({ message: error }));
  }
}
export function* searchCollectionAsync(action) {
  const { search } = require("./collection.data");
  const { links } = require("../api.links");
  const keys = search[action.payload.searchType].headers[0].columns;
  let collections = yield select(selectCollections);
  let filteredCollectionSnapshot = undefined;
  let filteredChartSnapshot = undefined;
  const formFilters = action.payload.searchForm;

  if (formFilters && Object.keys(formFilters).length) {
    //filtering charts and tabular data
    const url = links[action.payload.searchType];
    const user = yield select(selectCurrentUser);
    const json = yield fetch(url, {
      method: "POST",
      headers: {
        AuthenticationToken: user.accessToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formFilters),
    }).then((response) => response.json());
    //collections = json.results;
    if (action.payload.searchType === "HOME") {
      filteredChartSnapshot = json.results;
    } else {
      filteredCollectionSnapshot = json.results;
    }
  } else {
    filteredCollectionSnapshot = collections.filter((item) => {
      //local searching redux store
      let propExist = false;
      keys.every(function (element, index) {
        const searching = element.accessor;
        if (item[searching] && searching !== "sr") {
          propExist = item[searching]
            .toLowerCase()
            .includes(action.payload.searchItem.toLowerCase());
          if (propExist) {
            return false;
          }
          return true;
        }
        return true;
      });
      return propExist;
    });
  }

  const headers = search[action.payload.searchType].headers;
  try {
    const snap = {
      collections: collections,
      filteredCollections: filteredCollectionSnapshot,
      headers: headers,
      chartdata: filteredChartSnapshot,
    };
    yield put(searchCollectionSuccess(snap));
  } catch (error) {
    yield put(fetchCollectionsFailure(error.message));
  }
}
export function* fetchCollectionsStart() {
  yield takeLatest(
    CollectionActionTypes.FETCH_COLLECTIONS_START,
    fetchCollectionsAsync
  );
}
export function* fetchUserStart() {
  yield takeLatest(
    CollectionActionTypes.FETCH_USER_START,
    fetchCollectionsAsync
  );
}
export function* fetchPermissionStart() {
  yield takeLatest(
    CollectionActionTypes.FETCH_PERMISSION_START,
    fetchCollectionsAsync
  );
}
export function* fetchNotificationStart() {
  yield takeLatest(
    CollectionActionTypes.FETCH_NOTIFICATION_START,
    fetchCollectionsAsync
  );
}
export function* fetchComplaintStart() {
  yield takeLatest(
    CollectionActionTypes.FETCH_COMPLAINT_START,
    fetchCollectionsAsync
  );
}
export function* fetchCommentStart() {
  yield takeLatest(
    CollectionActionTypes.FETCH_COMMENT_START,
    fetchCommentsAsync
  );
}
export function* fetchPartnerStart() {
  yield takeLatest(
    CollectionActionTypes.FETCH_PARTNER_START,
    fetchCollectionsAsync
  );
}
export function* fetchProgramStart() {
  yield takeLatest(
    CollectionActionTypes.FETCH_PROGRAM_START,
    fetchCollectionsAsync
  );
}
export function* fetchProjectStart() {
  yield takeLatest(
    CollectionActionTypes.FETCH_PROJECT_START,
    fetchCollectionsAsync
  );
}
export function* fetchCategoryStart() {
  yield takeLatest(
    CollectionActionTypes.FETCH_CATEGORY_START,
    fetchCollectionsAsync
  );
}
export function* fetchDocumentStart() {
  yield takeLatest(
    CollectionActionTypes.FETCH_DOCUMENT_START,
    fetchCollectionsAsync
  );
}
export function* fetchTypeStart() {
  yield takeLatest(
    CollectionActionTypes.FETCH_TYPE_START,
    fetchCollectionsAsync
  );
}
export function* fetchStatusStart() {
  yield takeLatest(
    CollectionActionTypes.FETCH_STATUS_START,
    fetchCollectionsAsync
  );
}
export function* fetchSourceStart() {
  yield takeLatest(
    CollectionActionTypes.FETCH_SOURCE_START,
    fetchCollectionsAsync
  );
}
export function* fetchProvinceStart() {
  yield takeLatest(
    CollectionActionTypes.FETCH_PROVINCE_START,
    fetchCollectionsAsync
  );
}
export function* fetchUcStart() {
  yield takeLatest(CollectionActionTypes.FETCH_UC_START, fetchCollectionsAsync);
}
export function* fetchTehsilStart() {
  yield takeLatest(
    CollectionActionTypes.FETCH_TEHSIL_START,
    fetchCollectionsAsync
  );
}
export function* fetchVillageStart() {
  yield takeLatest(
    CollectionActionTypes.FETCH_VILLAGE_START,
    fetchCollectionsAsync
  );
}
export function* fetchDistrictStart() {
  yield takeLatest(
    CollectionActionTypes.FETCH_DISTRICT_START,
    fetchCollectionsAsync
  );
}
export function* fetchTagStart() {
  yield takeLatest(
    CollectionActionTypes.FETCH_TAG_START,
    fetchCollectionsAsync
  );
}
export function* fetchDetailStart() {
  yield takeLatest(CollectionActionTypes.FETCH_DETAIL_START, fetchDetailAsync);
}
export function* fetchReportStart() {
  yield takeLatest(
    CollectionActionTypes.FETCH_REPORT_START,
    fetchCollectionsAsync
  );
}
export function* fetchHomeStart() {
  yield takeLatest(CollectionActionTypes.FETCH_HOME_START, fecthHomeAsync);
}
export function* searchCollectionStart(item, type) {
  yield takeEvery(
    CollectionActionTypes.SEARCH_COLLECTION_START,
    searchCollectionAsync
  );
}
export function* createCollectionStart(item) {
  yield takeLatest(
    CollectionActionTypes.SAVE_COLLECTION_START,
    saveCollectionAsync
  );
}
export function* saveCommentStart(item) {
  yield takeLatest(CollectionActionTypes.SAVE_COMMENT_START, saveCommentAsync);
}
export function* addComplaintStatusStart(item) {
  yield takeLatest(
    CollectionActionTypes.ADD_COMPLAINT_STATUS_START,
    addComplaintStatusAsync
  );
}
export function* updateCollectionStart(item) {
  yield takeLatest(
    CollectionActionTypes.UPDATE_COLLECTION_START,
    saveCollectionAsync
  );
}
export function* reloadDataAfterModify({ payload }) {
  //yield console.log("testing",payload);
}
export function* onCreateCollectionSuccess() {
  yield takeLatest(
    CollectionActionTypes.SAVE_COLLECTION_SUCCESS,
    reloadDataAfterModify
  );
}
export function* onlineComplaintAsync(action) {
  const { links } = require("../api.links");
  const url = links.ONLINE;
  const json = yield fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(action.payload),
  }).then((response) => response.json());
  yield notify(json);
  yield put({
    type: CollectionActionTypes.ONLINE_COMPLAINT_COMPLETE,
    payload: json,
  });
}
export function* onlineComplaint() {
  yield takeLatest("SEND_COMPLAINT", onlineComplaintAsync);
}
export function* fetchComplaintDetailStart() {
  yield takeEvery("FETCH_COMPLAINT_DETAIL_START", fetchComplaintDetilAsync);
}
export function* collectionSagas() {
  yield all([
    call(onlineComplaint),
    call(fetchHomeStart),
    call(fetchUserStart),
    call(fetchNotificationStart),
    call(fetchPermissionStart),
    call(fetchCollectionsStart),
    call(fetchReportStart),
    call(fetchComplaintStart),
    call(fetchCommentStart),
    call(fetchPartnerStart),
    call(fetchProgramStart),
    call(fetchProjectStart),
    call(fetchDocumentStart),
    call(fetchCategoryStart),
    call(fetchTypeStart),
    call(fetchStatusStart),
    call(fetchSourceStart),
    call(fetchProvinceStart),
    call(fetchUcStart),
    call(fetchTehsilStart),
    call(fetchVillageStart),
    call(fetchDistrictStart),
    call(fetchTagStart),
    call(fetchDetailStart),
    call(fetchComplaintDetailStart),
    call(searchCollectionStart),
    call(createCollectionStart),
    call(saveCommentStart),
    call(onCreateCollectionSuccess),
    call(addComplaintStatusStart),
  ]);
}
