import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
	html{
		overflow:hidden;
	}
	body {
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
		@media screen and (max-width: 800px) {
		}
	}

	a {
		text-decoration: none;
		color: black;
		font-family: 'NoirPro', sans-serif;
    	font-variant: tabular-nums;
	}

	* {
		box-sizing: border-box;
	}
	.page{
		/*
		grid-template-columns: 18vw auto;
    	display: grid;
		*/
		overflow:hidden;
	}
	.sidebar{
		  background:#5b005a;
		  min-height:88vh;
		  float:left;
	}
	.float-left{
		float:left;
	}
	.tabular-content-holder{
		height:80vh;
		background:#f5f5f5;
		padding: 6px;
	}
	
	.floatRight{
        float:right;
    }
	.ant-layout-sider-collapsed .gx-sidebar-notifications{
		display:none;
	}
	.icon{
		color:#9b679b;
	}
	.gx-main-content-wrapper{
		overflow-y: scroll;
		height: 80vh;
	}
	@media screen and (max-width: 800px) {
		.tabular-content-holder{
			width: 98vw;
		}
		.gx-main-content-wrapper{
			overflow: scroll;
    		height: 70vh;
		}
		    
	}
	.flex-col{
		display:flex;
	}
	.inline-form{
		display:flex;
		flex-wrap:wrap;
	}

	.inline-button{
		margin-left:10px;
	}
	td{
		text-transform: capitalize;
	}
	
`;
