import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from './user/user.reducer';
import cartReducer from './cart/cart.reducer';
import directoryReducer from './directory/directory.reducer';
import collectionReducer from './collection/collection.reducer';
import uploadReducer from './upload/upload.reducer';
import settings from "./settings/settings.reducers";
import lookupReducer from './lookup/lookup.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['settings','user']
};
const rootReducer = combineReducers({
  settings: settings,
  upload:uploadReducer,
  user: userReducer,
  cart: cartReducer,
  directory: directoryReducer,
  collection: collectionReducer,
  lookup:lookupReducer
});

export default persistReducer(persistConfig, rootReducer);
