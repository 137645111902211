
import LookupActionTypes from './lookup.types';
import {CollectionActionTypes} from '../collection/collection.types';

const INITIAL_STATE = {
};

const lookupReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LookupActionTypes.FETCH_DROPDOWN_OPTIONS:
            case CollectionActionTypes.FETCH_DROPDOWN_OPTIONS:
      const { normalize, schema } = require("normalizr");
      const board = new schema.Entity(
        "dataByIds",
        {},
        { idAttribute: "_id" }
      );
      let data = {};
      Object.keys(action.payload).forEach(key => {
           data = {...data,...normalize(action.payload[key], [board]).entities.dataByIds};
          //dataById.push(normalize(action.payload[key], [board]))
      });
    return data;
    default:
      return state;
  }
};

export default lookupReducer;