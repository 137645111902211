import styled from "styled-components";

export const ProgressContainer = styled.span`
  .progress {
    vertical-align: baseline;
    display: flex;
    width: 100%;
    margin: 5px 0;
  }
`;
export const UploadedFileContainer = styled.span`
  padding: 10px;
  background: lightblue;
  margin: 5px 5px;
  display: inline-flex;
  button {
    position: absolute;
    background: none;
    border: none;
    margin-top: -30px;
    margin-left: -15px;
    padding: 0;
    cursor: alias;
  }
  .file-name {
    display: flex;
    flex-direction: column;
    &:hover {
      cursor: pointer;
    }
  }
`;
