import styled from "styled-components";
import { Link } from "react-router-dom";
export const ProfileDropDown = styled.div`
  position: absolute;
  background: #f5f5f5;
  padding: 10px;
  min-height: 100px;
  border: solid 1px #ccc;
  margin-top: 10px;
  z-index: 10;
  .uploader {
    display: none;
  }
  ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    li {
      display: block;
      a,
      label {
        color: #5b005a;
        cursor: pointer;
        &:hover {
          color: blue;
        }
      }
      i {
        padding: 4px;
      }
    }
  }
`;
export const HeaderContainer = styled.div`
  grid-template-columns: ${(props) =>
    props.full ? (props.isCollapsed ? "5.88vw auto" : "18vw auto") : "100vw"};
  justify-content: initial;
  color: #5b005a;
  display: flex;
  min-height: 60px;
  ${(props) => (props.full ? null : "background: #470146;")};
  .header-right-sec-wrapper {
    width: calc(100% - 280px);
  }
  @media (max-width: 990px) {
    .header-right-sec-wrapper {
      width: calc(100% - 70px);
    }
  }
  @media (min-width: 1024px) {
    .ant-layout-sider-zero-width-trigger {
      opacity: 0;
    }
  }
`;
export const OrganizationContainer = styled.div`
  position: absolute;
  margin: 18px;
  font-size: 1.5em;
  text-transform: capitalize;
`;
export const BeforeSignInContainer = styled.div`
  width: -webkit-fill-available;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  width: 100%;
  .online-form {
    color: #fff;
  }
`;
export const LogoContainer = styled(Link)`
  display: flex;
  background: #470146;
  width: 280px;
  .logo {
    width: 100%;
    padding: 10px;
    ${(props) => (props.full ? "width:100%" : null)};
  }
  @media screen and (max-width: 800px) {
    width: 50vw;
    padding: 0;
  }
  @media (max-width: 990px) {
    width: 70px;
  }
`;

export const OptionsContainer = styled.div`
  background: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media screen and (max-width: 800px) {
  }
`;

export const LogoutIcon = styled.span`
  & i {
    position: relative;
    right: 10px;
    top: -1px;
  }
`;

export const OptionLink = styled(Link)`
  padding: 10px 15px;
  cursor: pointer;
  font-size: 12px;
`;

export const LogoWrapper = styled.div`
  @media (max-width: 990px) {
    width: 45px;
    overflow: hidden;
    display: block;
    margin: 7px auto 0;
    .logo {
      min-width: 315px;
      padding: 0;
    }
  }
`;

export const UserMeta = styled.ul`
  li {
    color: #5c0d5b !important;
    font-size: 15px !important;
    text-transform: capitalize !important;
    img {
      width: 42px;
      height: 40px;
    }
  }
`;
